/** @format */

import _ from 'lodash';

let whitelabel =
  process.env.REACT_APP_USE_FAKE_WHITELABEL === 'true'
    ? {
        id: 'mollybet_skaffold', //whitelabel id, must much what you use on the backend
        name: 'Mollybet (Skaffold)', //name that appears in the title

        basePath: '',
        sonicRedirect: true,
        sonicUrl: '/alpha/trade',

        feedbackEmail: 'info@mollybet.com', //email where the feedback goes (the backend also cc's molly support)
        //copyright: 'Gambit Research',
        //contactTel: '+44 20 3137 1421',
        mobilePlatformLink: '//m.molly.skaffold', //where does the mobile redirect go
        desktopPlatformLink: '//molly.skaffold', //where does the desktop redirect go
        demoPlatformLink: '//demo.molly.skaffold', //what host should we assume is the demo
        featureFlags: [
          'depositButton', //show the deposit button (the whitelabel must also have Whitelabel.externalUrls['deposit'])
        ],
        //supports {token} and {lang}
        externalUrls: {
          deposit: '//mollybet.give/{token}/{lang}/',
        },
        cookieDomain: '.molly.skaffold', //what domains is the session cookie available for (can also be an array)
        gaID: 'UA-133217785-1', //google analytics id used to set up ReactGA

        poweredBy: true, //show powered by mollybet text
        ageRestriction: true, //show age restriction text
        registerLink: '/registration', //adds the registration link below login
        termsLink: '/wl/mollybet/terms/all/en/terms.html', //this adds a general terms link below login
        responsibleGamblingLink: '/responsible_gambling.html', //this adds a responsible gambling link below login
        privacyLink: '/wl/mollybet/terms/all/en/privacy.html', //this adds a general terms link below login
        canResetPassword: true, //adds the "forgot password" option inside the login box

        //where the variations of the logos re
        logos: {
          coin: {
            dark: '/wl/mollybet/logos/logo-white.svg',
            light: '/wl/mollybet/logos/logo-black.svg',
          },
          wide: {
            dark: '/wl/mollybet/logos/logo-complete-white.svg',
            light: '/wl/mollybet/logos/logo-complete-black.svg',
          },
        },

        //use molly style animated prices background on login page
        animatedBackground: true,
        backgroundPattern: '/../wl/mollybet/pattern.svg',
        backgroundStyle: {
          backgroundSize: '80px',
        },

        //map of terms
        terms: {
          all: {
            //visible for all groups
            terms: {
              label: { id: 'terms.side.termsOfUse', defaultMessage: 'Terms of Use' }, //name
              url: 'terms.html', //file
              lastUpdated: '2018-10-16', //time when terms were last updated, changing this will cause user to re-accept
            },
            privacy: {
              label: { id: 'terms.side.privacy', defaultMessage: 'Privacy Policy' },
              url: 'privacy.html',
              lastUpdated: '2018-10-16',
            },
            acceptable: {
              label: { id: 'terms.side.acceptable', defaultMessage: 'Acceptable Use' },
              url: 'acceptable.html',
              lastUpdated: '2018-10-16',
            },
            responsible: {
              label: { id: 'terms.side.responsible', defaultMessage: 'Responsible Gambling' },
              url: 'responsible.html',
              lastUpdated: '2018-10-16',
            },
          },
          '1431': {
            //you can define special terms for individual groups
            terms: {
              label: { id: 'terms.side.termsOfUse', defaultMessage: 'Terms of Use' },
              url: 'terms.html',
              lastUpdated: '2018-10-16',
            },
            privacy: {
              label: { id: 'terms.side.privacy', defaultMessage: 'Privacy Policy' },
              url: 'privacy.html',
              lastUpdated: '2018-10-16',
            },
            acceptable: {
              label: { id: 'terms.side.acceptable', defaultMessage: 'Acceptable Use' },
              url: 'aup.html',
              lastUpdated: '2018-10-16',
            },
            responsible: {
              label: { id: 'terms.side.responsible', defaultMessage: 'Responsible Gambling' },
              url: 'responsible.html',
              lastUpdated: '2018-10-16',
            },
          },
        },

        //map of announcements
        announcements: {
          accumulators: {
            url: 'accumulators.html',
            lastUpdated: '2022-11-09',
          },
          smartCredit: {
            label: { id: 'terms.side.smartCredit', defaultMessage: 'New Feature: Smart Credit' },
            url: 'smartCredit.html',
            lastUpdated: '2024-01-26',
          },
          horse: {
            label: { id: 'terms.side.horse', defaultMessage: 'Horse Racing now available' },
            url: 'horse.html',
            lastUpdated: '2024-03-12',
          },
          beta: {
            label: { id: 'terms.side.beta', defaultMessage: 'New Platform now in Beta!' },
            url: 'beta.html',
            lastUpdated: '2024-07-15',
          },
          politics: {
            label: {
              id: 'terms.side.politics',
              defaultMessage: 'Politics betting now available on Beta',
            },
            url: 'politics.html',
            lastUpdated: '2024-07-19',
          },
          greyhound: {
            label: {
              id: 'terms.side.greyhound',
              defaultMessage: 'Greyhound Racing now available on Beta',
            },
            url: 'greyhound.html',
            lastUpdated: '2024-07-24',
          },
        },

        //override defaults
        defaults: {},

        //configuration overrides
        config: {
          //list of supported languages
          supportedLanguages: ['en', 'pt', 'ru', 'es', 'tr', 'zh'],

          //some redistration restrictions and info
          registration: {
            currencies: ['usd', 'gbp', 'eur'], //what currencies are supported for account creation
            groupId: 1431, //where registered users land (used to show t&c as well)
            kycEmail: 'kyc@mollybet.com',
          },

          //some theme overrides (applies to MUI, but SC also fishes out the accent color)
          themes: {
            default: {
              palette: {
                common: {
                  accent: '#34ac70',
                },
              },
            },

            light: {
              palette: {
                common: {
                  accent: '#838dff',
                },
              },
            },
          },
        },
      }
    : {};

if (window && window.WL_CONFIG) {
  _.merge(whitelabel, window.WL_CONFIG);
  if (window.WL_CONFIG.config.supportedLanguages) {
    whitelabel.config.supportedLanguages = window.WL_CONFIG.config.supportedLanguages;
  }
}

export default whitelabel;
