/** @format */

import DSM from '../../lib/DSM';
import Telemetry from '../../lib/Telemetry';
import { toCamelCase } from '../../lib/camelSnake';
import { toBetParams } from '../../lib/betTypes';
import { formatPriceToDecimal, extractErrorMessage } from '../../lib/formatters';
import { generatePlacerId, tentativePMMComposition, isExchange } from '../../lib/placers';
import weightedMedian from 'weighted-median';

import config from '../../config';
import _ from 'lodash';
import { v4 } from 'uuid';
import { fromJS } from 'immutable';

// split out as a helper function as used by multiple actions
const closePlacerHelperFunction = (actions, placerId, state) => {
  let placer = state.getIn(['placers', placerId], null);

  if (placer) {
    //get the ids of all the betslips in the placer
    let ids = [];
    let betslips = placer.getIn(['betslips'], null);
    if (betslips) {
      betslips.forEach((betslip, betslipId) => {
        ids.push(betslipId);
      });
    }

    //for each betslip make a close request to the API
    //this is fire and forget... we will also get close messages down the websockit
    //we may want a way of closing multiple betslips in one request?
    for (let id of ids) {
      state = state.removeIn(['betslipPlacers', id]);
      DSM.create(
        `/v1/betslips/${id}/`,
        {
          method: 'DELETE',
          message: 'betslipCloseResponse',
          ignoreErrors: true, //it's ok to fail
          extras: {
            placerId,
          },
        },
        actions
      );
    }

    state = state.removeIn(['placerBetslips', placerId]);
    state = state.removeIn(['placers', placerId]);

    return state;
  }

  return state;
};

//it's again possible the websocket spews stuff before the REST response arrives
let betslipMessageBuffer = {};

const INITIAL_STATE = {
  //map of open betslip placers
  placers: {},

  //sometimes prices come before the betslip request is done
  //therefore we need to maintain a buffer for messages
  exchanges: {},

  //because of poor life choices it's hard to keep track of where betslips are
  //so we need a map that's betslipId:placerId
  betslipPlacers: {},
  //we also need the reverse (placerId:betslipIds) as an optimization
  placerBetslips: {},
  //we also need betslip accounts associations for the same reason
  betslipAccounts: {},

  //count how many betslips are open
  openBetslips: 0,
  //display some sort of warning for opening too many
  openBetslipsWarning: false,
  //display some sort of warning for rate limiting
  throttledBetslipsWarning: 0,
};

let initialState = fromJS(INITIAL_STATE);

//let lastTentativeRecalcs = {};

let _pmm_count = 0;
let _betslip_count = 0;
if (config.support.tools.betslipStress) {
  setInterval(() => {
    if (window._betslip_stress_running) {
      console.warn(
        `pmms last ${
          config.timings.pmmCountInterval / 1000
        } seconds: [${_pmm_count}] from [${_betslip_count}] betslips`
      );
      _pmm_count = 0;
    }
  }, config.timings.pmmCountInterval);
}

const resetChiclets = (state, betslipId, placerId) => {
  // need to update the chiclets
  const liquidityMap = state.getIn(
    ['placers', placerId, 'betslips', betslipId, 'liquidity', 'prices'],
    fromJS({})
  );

  const summedTotals = [];
  const accountsPriceAndStakeData = [];

  // sum up the liquidity available at each given price
  liquidityMap.mapEntries(([price, stakeData]) => {
    const total = stakeData.get('total') || null;
    let totalFromTickedAccounts = 0;
    // if not total then there is nothing to calculate
    if (total) {
      // otherwise calculate what the real total is from selected accounts
      stakeData.keySeq().forEach((key) => {
        const accountIsTicked = state.getIn(
          ['placers', placerId, 'betslips', betslipId, 'accounts', key, 'isUsed'],
          false
        );
        if (key !== 'total' && accountIsTicked) {
          totalFromTickedAccounts = totalFromTickedAccounts + stakeData.get(key);
        }
      });
    }
    // if this is not 0 - so there is a val - push to data list
    if (totalFromTickedAccounts) {
      accountsPriceAndStakeData.push({ value: price, weight: totalFromTickedAccounts });
    }
  });

  const median = weightedMedian(accountsPriceAndStakeData);

  let priceClosestToMedianTotal = undefined;

  const betslipType = state.getIn(
    ['placers', placerId, 'betslips', betslipId, 'betslipType'],
    null
  );

  // here is where we get the min and max
  // also where we get the stake available at the median

  if (betslipType === 'normal') {
    // we sort the the stake/price objects in descending value of price
    // why? because the best price is the highest and every price after that
    // at that price we have stake available to that price and stake available
    // at all higher prices
    let sum = 0;
    accountsPriceAndStakeData.sort((a, b) => b.value - a.value);
    accountsPriceAndStakeData.forEach((el) => {
      if (priceClosestToMedianTotal === undefined && median >= el.value) {
        if (median === el.value) {
          priceClosestToMedianTotal = sum + el.weight;
        } else {
          priceClosestToMedianTotal = sum;
        }
      }
      sum = sum + el.weight;
      summedTotals.push({ price: el.value, total: sum });
    });
  } else {
    //betslipType must be lay
    // so we sort them in ascending order not descending
    let sum = 0;
    accountsPriceAndStakeData.sort((a, b) => a.value - b.value);
    accountsPriceAndStakeData.forEach((el) => {
      // here is another change to above where we are operating on back prices
      // we want the first price that is greater than or equal to the median
      // as that is the first worse price and has the amount of stake that we can guarantee
      if (priceClosestToMedianTotal === undefined && median <= el.value) {
        if (median === el.value) {
          priceClosestToMedianTotal = sum + el.weight;
        } else {
          priceClosestToMedianTotal = sum;
        }
      }
      sum = sum + el.weight;
      summedTotals.push({ price: el.value, total: sum });
    });
  }

  if (betslipType === 'normal') {
    state = state.setIn(
      ['placers', placerId, 'betslips', betslipId, 'chicletData'],
      fromJS({
        min: summedTotals[summedTotals.length - 1],
        median: { price: median, total: priceClosestToMedianTotal },
        max: summedTotals[0],
      })
    );
  } else {
    // must be type lay
    state = state.setIn(
      ['placers', placerId, 'betslips', betslipId, 'chicletData'],
      fromJS({
        max: summedTotals[0],
        median: { price: median, total: priceClosestToMedianTotal },
        min: summedTotals[summedTotals.length - 1],
      })
    );
  }

  return state;
};

const functions = {
  //you need this action itterator wherever you use the pricefeed websocket
  data: (state, action) => {
    //batch updates
    state = state.asMutable();
    //apply multiple things
    if (action.data.data && typeof action.data.data === 'object') {
      for (let act of action.data.data) {
        state = reducer(state, { type: act[0], data: act[1] });
      }
    }

    return state.asImmutable();
  },

  //api stream is proxied so it has to be unpacked
  api: (state, action) => {
    state = state.asMutable();

    let _data;
    if (action.data.ts) {
      _data = action.data.data;
    } else {
      _data = action.data[0].data;
    }

    let betslipsToRecalculate = [];
    if (_data) {
      for (let _action of _data) {
        state = reducer(state, { type: _action[0], data: _action[1] });
        if (_action[1] && _action[1].betslipId) {
          betslipsToRecalculate.push(_action[1].betslipId);
        }
      }
    }

    betslipsToRecalculate = _.uniq(betslipsToRecalculate);
    if (betslipsToRecalculate.length) {
      //let timeNow = new Date();
      for (let betslipId of betslipsToRecalculate) {
        let placerId = state.getIn(['betslipPlacers', betslipId], 'none');
        let isReady = state.getIn(['placers', placerId, 'betslips', betslipId, 'isReady'], false);
        let accounts = state.getIn(['placers', placerId, 'betslips', betslipId, 'accounts'], null);
        if (
          isReady &&
          accounts &&
          accounts.size //&&
          // (!lastTentativeRecalcs[betslipId] ||
          //   timeNow - lastTentativeRecalcs[betslipId] > config.timings.tentativePMMCalcDebounce)
        ) {
          state = tentativePMMComposition(state, placerId, betslipId);
          //lastTentativeRecalcs[betslipId] = timeNow;
        }
      }
    }

    return state.asImmutable();
  },

  ////// OLD BETSLIP CLEANUP

  //if the user is not being sudoed, kill all betslips
  //we have to get all the old betslip and close them
  loginResponse: (state, action) => {
    if (action.data.status === 'ok' && action.data.data && !action.data.data.sudoer) {
      //get all his betslips
      DSM.last(
        `/v1/betslips/`,
        {
          method: 'GET',
          message: 'getAllBetslips',
        },
        action.data.actions,
        'getAllBetslips'
      );
    } else {
      //supposedly error handler will do someting
    }

    return state;
  },

  //get all the open betslips and close them
  getAllBetslips: (state, action) => {
    if (action.data.status === 'ok') {
      for (let idx in action.data.data) {
        let betslipId = action.data.data[idx];
        DSM.create(
          `/v1/betslips/${betslipId}/`,
          {
            method: 'DELETE',
            message: 'betslipCloseOldResponse',
            ignoreErrors: true, //it's ok to fail
          },
          action.data.actions
        );
      }
    } else {
      //supposedly error handler will do someting
    }

    return state;
  },

  //we don't really need to do anything here
  betslipCloseOldResponse: (state, _action) => {
    //do nothing, it's ok
    return state;
  },

  hideExchange: (state, action) => {
    const { exchangeId } = action.data;
    state = state.setIn(['exchanges', 'hiddenExchanges', exchangeId], true);
    return state;
  },

  hideBetslip: (state, action) => {
    const { betslipId } = action.data;
    state = state.setIn(['exchanges', 'hiddenBetslips', betslipId], true);
    return state;
  },

  unhideBetslip: (state, action) => {
    const { betslipId } = action.data;
    state = state.deleteIn(['exchanges', 'hiddenBetslips', betslipId]);
    return state;
  },

  openExchange: (state, action) => {
    const { back, lay, exchangeId, info } = action.data;
    const backPlacedId = back.placerId;
    const layPlacerId = generatePlacerId(lay);
    state = state.setIn(
      ['exchanges', 'activeExchanges', `${exchangeId}`, 'back'],
      fromJS({ ...back, placerId: backPlacedId })
    );
    state = state.setIn(
      ['exchanges', 'activeExchanges', `${exchangeId}`, 'lay'],
      fromJS({ ...lay, placerId: layPlacerId })
    );
    state = state.setIn(['exchanges', 'exchangeBetslips', back.betslipId], exchangeId);
    state = state.setIn(
      ['exchanges', 'activeExchanges', `${exchangeId}`, 'info'],
      fromJS({
        ...info,
        isLoading: true,
        zIndex: +new Date(),
        sport: back.sport,
        eventId: back.eventId,
        competitionId: back.competitionId,
        home: info.home,
        away: info.away,
        betTypeDescription: info.betTypeDescription,
        marketId: info.marketId,
      })
    );
    state = state.setIn(['exchanges', 'layPlacersToIgnore', layPlacerId], exchangeId);

    const disabledBookies = state.getIn(
      ['placers', back.placerId, 'betslips', back.betslipId, 'disabledBookies'],
      []
    );

    const accounts = state.getIn(
      ['placers', back.placerId, 'betslips', back.betslipId, 'accounts'],
      []
    );

    // mark as unused all accounts with their bookie disabled
    accounts.keySeq().forEach((betslipAccountId) => {
      const bookie = betslipAccountId.split('/')[0];
      if (disabledBookies.includes(bookie)) {
        state = state.setIn(
          [
            'placers',
            back.placerId,
            'betslips',
            back.betslipId,
            'accounts',
            betslipAccountId,
            'isUsed',
          ],
          false
        );
      }
    });

    return state;
  },

  // here clear all betslips related to the exchanges and placers
  closeExchange: (state, action) => {
    let { exchangeId, backBetslipId } = action.data;
    const backPlacerId = state.getIn(
      ['exchanges', 'activeExchanges', exchangeId, 'lay', 'placerId'],
      null
    );
    const layPlacerId = state.getIn(
      ['exchanges', 'activeExchanges', exchangeId, 'back', 'placerId'],
      null
    );
    if (backBetslipId === undefined) {
      backBetslipId = state.getIn(
        ['exchanges', 'activeExchanges', exchangeId, 'back', 'betslipId'],
        undefined
      );
    }
    state = state.deleteIn(['exchanges', 'exchangeBetslips', backBetslipId]);
    state = state.deleteIn(['exchanges', 'hiddenExchanges', exchangeId]);
    state = state.deleteIn(['exchanges', 'activeExchanges', `${exchangeId}`]);
    state = state.deleteIn(['exchanges', 'layPlacersToIgnore', layPlacerId]);
    if (action.data.closeBackPlacers) {
      state = closePlacerHelperFunction(action.data.actions, backPlacerId, state);
    }
    if (action.data.closeLayPlacers) {
      state = closePlacerHelperFunction(action.data.actions, layPlacerId, state);
    }
    return state;
  },

  closeAllExchanges: (state) => {
    // this assumes that you have called closeAllPlacers first - otherwise this method will only be doing half the job
    state = state.set('exchanges', fromJS({}));
    return state;
  },

  ////// PLACERS and BETSLIPS

  //this makes a betslip open request to the API
  //this is the main thing that is used not BETSLIP_UPDATE
  //this is also opens a plaver

  betslipOpen: (state, action) => {
    state = state.asMutable();
    let op = state.get('openBetslips', 0);
    if (op === action.data.maxBetslips) {
      return state.set('openBetslipsWarning', true);
    }

    let placerId = generatePlacerId(action.data);
    let placer = state.getIn(['placers', placerId], null);

    if (!placer) {
      state = state.setIn(
        ['placers', placerId],
        fromJS({
          ...action.data,
          placerId,
          isLoading: true,
          zIndex: +new Date(),
          manuallyClosedHandicaps: {},
          baseBetType: toBetParams(action.data.betType).baseBetType,
        })
      );
      placer = state.getIn(['placers', placerId], null);
      //} else if (action.data.exchangeId !== undefined) {
    } else {
      // we are creating an exchange - if the against betslip exists delete it
      let betslips = state.getIn(['placers', placerId, 'betslips'], null);
      let abort = false;
      if (betslips) {
        betslips.forEach((betslip, _) => {
          // betslip already exists - we need to delete it as it is a back betslip and we need a lay one
          if (action.data.betType === betslip.get('betType', '')) {
            const betslipId = betslip.get('betslipId');
            if (action.data.exchangeId !== undefined) {
              state.deleteIn(['placers', placerId, 'betslips', betslipId]);
              DSM.create(
                `/v1/betslips/${betslipId}/`,
                {
                  method: 'DELETE',
                  message: 'betslipCloseResponse',
                  ignoreErrors: true, //it's ok to fail
                  extras: {
                    betslipId,
                    placerId,
                    closePlacer: false,
                  },
                },
                action.data.actions
              );
            } else {
              // betslip already exists so do nothing
              abort = true;
            }
          }
        });
      }

      if (abort) {
        return state;
      }
    }

    if (action.data.disabledBookies) {
      state = state.setIn(['placers', placerId, 'disabledBookies'], action.data.disabledBookies);
    }

    let body = {
      sport: action.data.sport,
      eventId: action.data.eventId,
      betType: action.data.betType,
      equivalentBets: action.data.equivalentBets,
      multipleAccounts: action.data.multipleAccounts,
      betslipType: action.data.isLayBetslip ? 'lay' : 'normal',
    };

    if (action.data.waitForPmms) {
      body['waitForPmms'] = action.data.waitForPmms;
    }

    if (
      action.data.bookieMinBalances &&
      typeof action.data.bookieMinBalances === 'object' &&
      action.data.bookieMinBalances.toJS
    ) {
      body['bookieMinBalances'] = action.data.bookieMinBalances.toJS() || {};
    }

    //betslipu requestu
    DSM.create(
      '/v1/betslips/',
      {
        method: 'POST',
        body,
        extras: {
          placerId,
          asMultipleHandicap: action.data.asMultipleHandicap,
          exchangeId: action.data.exchangeId,
          params: action.data.params,
        },
        message: 'betslipOpenResponse',
      },
      action.data.actions
    );
    return state.asImmutable();
  },

  //this handles the respons from opening a betslip on the server
  //it is possible that the placer exists already (if this is a secondary handicap)
  betslipOpenResponse: (state, action) => {
    // #HACK - does placers none exist? no idea where from, so delte it
    state = state.deleteIn(['placers', 'none']);

    if (action.data.status === 'ok') {
      state = state.asMutable();
      let placerId = generatePlacerId(action.data.data);

      let disabledBookies = state.getIn(['placers', placerId, 'disabledBookies'], []);

      //for the case where it exist already
      state = state.setIn(['placers', placerId, 'isLoading'], false);

      let betslipId = action.data.data.betslipId;
      if (betslipId) {
        //record that the betslip belongs to the placer
        state = state.setIn(['betslipPlacers', betslipId], placerId);

        //we don't like the shape of accounts, it comes in as an array which is hard to work with
        //we want to make it into a map
        let accounts = {};
        let accs = action.data.data.accounts;
        for (let idx in accs) {
          let bookieAccountId = `${accs[idx].bookie}/${accs[idx].username}/${accs[idx].betType}`;
          accounts[bookieAccountId] = accs[idx];
          accounts[bookieAccountId].betslipAccountId = bookieAccountId;
          accounts[bookieAccountId].isUsed = disabledBookies.indexOf(accs[idx].bookie) === -1;
          state = state.setIn(['betslipAccounts', betslipId, bookieAccountId], true);
        }

        const params = action.data.extras.params !== undefined ? action.data.extras.params : {};

        //set the betslip data
        state = state.setIn(
          ['placers', placerId, 'betslips', betslipId],
          fromJS({
            ...action.data.data,
            ...params,
            betslipId,
            accounts,
            timeout: state.getIn(['placers', placerId, 'default', 'timeout'], 20),
            price: state.getIn(['placers', placerId, 'default', 'price']) || '', //might be null
            stake: state.getIn(['placers', placerId, 'default', 'stake']) || '', //might be null
            targetStake:
              action.data.extras && action.data.extras.asMultipleHandicap
                ? 0
                : state.getIn(['placers', placerId, 'targetStake'], 0), //target stake
            maxHedgeStake:
              action.data.extras && action.data.extras.asMultipleHandicap
                ? 0
                : state.getIn(['placers', placerId, 'maxHedgeStake'], 0), //max hedge stake
            hedgeType:
              action.data.extras && action.data.extras.asMultipleHandicap
                ? 0
                : state.getIn(['placers', placerId, 'hedgeType'], 0), //target stake
          })
        );

        //disabled bookies are special
        state = state.setIn(
          ['placers', placerId, 'betslips', betslipId, 'disabledBookies'],
          disabledBookies
        );

        //we need to record that the betslip is in the placer under that handicap
        state = state.setIn(['placerBetslips', placerId, betslipId], action.data.data.betType);

        let op = state.get('openBetslips', 0) + 1;
        state = state.set('openBetslips', op);

        if (action.data.extras.exchangeId) {
          // we need to record this lay bet in the exchange
          state = state.setIn(
            [
              'exchanges',
              'activeExchanges',
              `${action.data.extras.exchangeId}`,
              'lay',
              'betslipId',
            ],
            betslipId
          );
          state = state.setIn(
            ['exchanges', 'activeExchanges', `${action.data.extras.exchangeId}`, 'lay', 'placerId'],
            placerId
          );
          // if the betslip was opened as part of the exchange then we need to copy over the settings chosen in the back betslip
          // over to the lay betslip
          const backBetslipId = state.getIn(
            [
              'exchanges',
              'activeExchanges',
              `${action.data.extras.exchangeId}`,
              'back',
              'betslipId',
            ],
            null
          );
          const backPlacerId = state.getIn(
            [
              'exchanges',
              'activeExchanges',
              `${action.data.extras.exchangeId}`,
              'back',
              'placerId',
            ],
            null
          );

          const disabledBookies = state.getIn(
            ['placers', backPlacerId, 'betslips', backBetslipId, 'disabledBookies'],
            []
          );

          const accounts = state.getIn(
            ['placers', placerId, 'betslips', betslipId, 'accounts'],
            []
          );
          // mark as unused all accounts with the disabled bookie
          // copying this info over from the original backbetslip to the exchange
          accounts.keySeq().forEach((betslipAccountId) => {
            const bookie = betslipAccountId.split('/')[0];
            if (disabledBookies.includes(bookie)) {
              state = state.setIn(
                [
                  'placers',
                  placerId,
                  'betslips',
                  betslipId,
                  'accounts',
                  betslipAccountId,
                  'isUsed',
                ],
                false
              );
            }
          });
        }
      }

      state = tentativePMMComposition(state, placerId, betslipId);
      return state.asImmutable();
    } else {
      //mobile needs to know if a placer has 'failed'
      if (action.data.extras.placerId) {
        state = state.setIn(['placers', action.data.extras.placerId, 'betslipLoadFailure'], true);
      }

      if (action.data.response?.status === 429) {
        const retryTime = action.data.data.retryAfter ? parseInt(action.data.data.retryAfter) : -1;
        state = state.set('throttledBetslipsWarning', retryTime);
      }
      //handled by base
      return state;
    }
  },

  //marks that the betslip has been mounted and is ready to use
  //this prevents race conditions (i.e. continually updating vs mounting) and helps performance
  //dumps buffered messages
  betslipEnable: (state, action) => {
    let betslipId = action.data.betslipId;
    let placerId = state.getIn(['betslipPlacers', betslipId], 'none');

    state = state.asMutable();
    Telemetry.startRecording(`${betslipId}/firstPmm`, 'betslip_first_pmm');
    state = state.setIn(['placers', placerId, 'betslips', betslipId, 'isReady'], true);

    //replay betslip_buffer to fix any missing prices
    let _betslipMessageBuffer = betslipMessageBuffer[betslipId];
    if (_betslipMessageBuffer) {
      for (let bMessage of _betslipMessageBuffer) {
        state = reducer(state, bMessage);
      }
      delete betslipMessageBuffer[betslipId];
    }

    state = tentativePMMComposition(state, placerId, betslipId);

    return state.asImmutable();
  },

  //this closes a betslip on the server
  betslipClose: (state, action) => {
    //close all brokers
    let betslipId = action.data.betslipId;
    let placerId = state.getIn(['betslipPlacers', betslipId], 'none');

    DSM.create(
      `/v1/betslips/${betslipId}/`,
      {
        method: 'DELETE',
        message: 'betslipCloseResponse',
        ignoreErrors: true, //it's ok to fail
        extras: {
          betslipId,
          placerId,
          closePlacer: action.data.closePlacer,
        },
      },
      action.data.actions
    );

    if (action.data.hasTouchedHandicaps) {
      state = state.setIn(
        ['placers', placerId, 'hasTouchedHandicaps'],
        action.data.hasTouchedHandicaps
      );

      state = state.setIn(
        ['placers', placerId, 'manuallyClosedHandicaps', action.data.handicap],
        true
      );
    }

    return state;
  },

  //just get rid of the betslip id just in case
  betslipCloseResponse: (state, action) => {
    if (action.data.status === 'ok') {
      state = state.asMutable();
      let betslipId = action.data.extras.betslipId;
      let placerId = action.data.extras.placerId; //there are some race conditions so we cant' get it from 'betslipPlacers'

      state = state.removeIn(['placers', placerId, 'betslips', betslipId]);
      state = state.removeIn(['betslipPlacers', betslipId]);
      state = state.removeIn(['placerBetslips', placerId, betslipId]);

      //check if the last betslip in placer
      let betslips = state.getIn(['placers', placerId, 'betslips'], null);

      if (!betslips || !betslips.size) {
        if (action.data.extras.closePlacer) {
          state = state.removeIn(['placers', placerId]);
        }
      }

      return state.asImmutable();
    } else {
      //handled by base
      return state;
    }
  },

  //this will close a placer
  //it has to do betslip cleanup as well
  closePlacer: (state, action) => {
    let placerId = action.data.placerId;
    let actions = action.data.actions;
    state = closePlacerHelperFunction(actions, placerId, state);
    return state;
  },

  //this pops placer to the front of the draggables
  popPlacer: (state, action) => {
    let placerId = action.data.placerId;
    let placer = state.getIn(['placers', placerId], null);

    if (placer) {
      return state.setIn(['placers', placerId, 'zIndex'], +new Date());
    }

    return state;
  },

  popExchange: (state, action) => {
    let exchangeId = action.data.exchangeId;
    let exchange = state.getIn(['exchanges', 'activeExchanges', exchangeId], null);

    if (exchange) {
      state = state.setIn(
        ['exchanges', 'activeExchanges', exchangeId, 'info', 'zIndex'],
        +new Date()
      );
    }

    return state;
  },

  //this closes all placers and does cleanup
  closeAllPlacers: (state, action) => {
    //get the ids of all the betslips in the placer
    let placers = state.get('placers', null);

    if (placers) {
      placers.forEach((placer, placerId) => {
        let ids = [];
        let betslips = placer.get('betslips', null);

        if (betslips) {
          betslips.forEach((betslip, betslipId) => {
            ids.push(betslipId);
          });
        }

        //for each betslip make a close request to the API
        //this is fire and forget... we will also get close messages down the websockit
        for (let id of ids) {
          state = state.removeIn(['betslipPlacers', id]);
          DSM.create(
            `/v1/betslips/${id}/`,
            {
              method: 'DELETE',
              message: 'betslipCloseResponse',
              ignoreErrors: true, //it's ok to fail
              extras: {
                placerId,
              },
            },
            action.data.actions
          );
        }

        state = state.removeIn(['placerBetslips', placerId]);
      });
    }

    return state.set('placers', fromJS({}));
  },

  //this is used to keep placer betslips alive
  //they expire after a while if we don't call this
  placerBetslipsRefresh: (state, action) => {
    let placerId = action.data.placerId;
    let placer = state.getIn(['placers', placerId], null);

    if (placer) {
      let ids = [];
      let betslips = placer.get('betslips', null);
      if (betslips) {
        betslips.forEach((betslip, betslipId) => {
          ids.push(betslipId);
        });
      }

      //fire and forget refresh request
      for (let id of ids) {
        DSM.create(
          `/v1/betslips/${id}/refresh/`,
          {
            message: 'placerBetslipsRefreshResponse',
            method: 'POST',
            body: {},
            extras: {
              betslipId: id,
            },
          },
          action.data.actions
        );
      }
    }

    return state;
  },

  //we don't really need to do anything here... we've wonned already
  placerBetslipsRefreshResponse: (state, action) => {
    if (action.data.status === 'ok') {
      return state;
    } else {
      state = state.asMutable();
      //kill the betslip
      let betslipId = action.data.extras.betslipId;
      let placerId = state.getIn(['betslipPlacers', betslipId], 'none');

      state = state.removeIn(['placers', placerId, 'betslips', betslipId]);
      state = state.removeIn(['betslipPlacers', betslipId]);
      state = state.removeIn(['placerBetslips', placerId, betslipId]);

      //check if the last betslip in placer
      let betslips = state.getIn(['placers', placerId, 'betslips'], null);
      if (!betslips || !betslips.size) {
        state = state.removeIn(['placers', placerId]);
      }

      return state.asImmutable();
    }
  },

  //close the placement status notification in the betslip
  dismissPlacementStatus: (state, action) => {
    let betslipId = action.data.betslipId;
    let placerId = state.getIn(['betslipPlacers', betslipId], 'none');
    state = state.setIn(['placers', placerId, 'betslips', betslipId, 'placementError'], '');
    return state.setIn(['placers', placerId, 'betslips', betslipId, 'hasPlaced'], false);
  },

  ////// API STREAM

  //this is the function that handles the update of the prices in the betslip pmm
  //[API]
  pmm: (state, action) => {
    if (config.support.tools.betslipStress) {
      _pmm_count++;
    }

    let betslipId = action.data.betslipId;

    let placerId = state.getIn(['betslipPlacers', betslipId], 'none');
    //let placer = state.getIn(['placers', placerId], null)
    let betslip = state.getIn(['placers', placerId, 'betslips', betslipId], null);
    let isReady = state.getIn(['placers', placerId, 'betslips', betslipId, 'isReady'], false);

    if (isReady) {
      //betslip exists fo' real and is ready
      //an account is uniquely identified by bookie and username and bet-type
      const betslipAccountId = `${action.data.bookie}/${action.data.username}/${action.data.betType}`;
      let disabledBookies = betslip.get('disabledBookies', []);

      if (
        action.data.priceList &&
        action.data.priceList.length &&
        !isExchange(action.data.bookie)
      ) {
        Telemetry.stopRecording(`${betslipId}/firstPmm`); //time for betslip first pmm
        Telemetry.stopRecording(
          `${action.data.eventId}/${action.data.sport}/${action.data.betType}/btsp`
        ); //total time open + first pmm
      }

      state = state.mergeDeepIn(
        ['placers', placerId, 'betslips', betslipId, 'accounts', betslipAccountId],
        {
          isUsed: state.getIn(
            ['placers', placerId, 'betslips', betslipId, 'accounts', betslipAccountId, 'isUsed'],
            disabledBookies.indexOf(action.data.bookie) === -1
          ),
          ...action.data,
        }
      );

      ////////////////////////////////////////////////////////////////////////////////////

      const bookieName = action.data.bookie;
      const accountName = action.data.username;
      const bookieAccountId = `${bookieName}/${accountName}/${action.data.betType}`;

      /////////////////////////////////////////////////////////////////////////////////////

      /* Check the max price offered by the pmm
      // the purpose of this is that on the exchange we will always show the account for a given bookie that has the highest max price
      // this is also updated by the betslip message which can kill a bookie account
      // in that case we need to get the next best price from a account with that bookie and put it here
       */
      const maxFromAccountPmm = !action.data.priceList.length
        ? null
        : action.data.priceList[0]?.effective.price;

      const prevMax = state.getIn(
        ['placers', placerId, 'betslips', betslipId, 'bestAccountForBookie', bookieName, 'max'],
        0
      );
      const prevMaxAccount = state.getIn(
        ['placers', placerId, 'betslips', betslipId, 'bestAccountForBookie', bookieName, 'id'],
        null
      );

      // if the max is higher than before and it is a different account
      // update the account being used and update with the new higher price
      if (prevMaxAccount !== betslipAccountId && prevMax < maxFromAccountPmm) {
        state = state.setIn(
          ['placers', placerId, 'betslips', betslipId, 'bestAccountForBookie', bookieName, 'id'],
          betslipAccountId
        );
        state = state.setIn(
          ['placers', placerId, 'betslips', betslipId, 'bestAccountForBookie', bookieName, 'max'],
          maxFromAccountPmm
        );
      } else if (prevMaxAccount === betslipAccountId) {
        // if it is the same account update it
        state = state.setIn(
          ['placers', placerId, 'betslips', betslipId, 'bestAccountForBookie', bookieName, 'max'],
          maxFromAccountPmm
        );
      } else {
        // we did not get a maxPrice - i.e. from molly being empty so we do nothing
        // OR the new price comes from a different account and is not better so we do nothing
      }

      /////////////////////////////////////////////////////////////////////////////////////

      /*
        Here we save a plot of prices vs liquidity
        This is saved as two maps inside: liquidity in each betslip
        price: {bookies: {bookieAccountId: stake, total: totalStake}}
        AND
        bookieAccountId: {price1: liquidity, price2: liquidity}
        The second map allows us to easily access the bookies influence on the first map
      */

      ////////////////////////////////////////////////////////////////////////////////////
      /* Wipe old liquidity data for this bookie */
      // dont have to check whether the account is selected here as we are removing data not adding it
      const prevPrices = state.getIn(
        ['placers', placerId, 'betslips', betslipId, 'liquidity', 'bookies', bookieAccountId],
        null
      );
      if (prevPrices !== null) {
        prevPrices.mapEntries(([price, liquidity]) => {
          const prevTotal = state.getIn(
            ['placers', placerId, 'betslips', betslipId, 'liquidity', 'prices', price, 'total'],
            null
          );
          state = state.deleteIn([
            'placers',
            placerId,
            'betslips',
            betslipId,
            'liquidity',
            'prices',
            price,
            bookieAccountId,
          ]);
          if (prevTotal) {
            const newTotal = prevTotal - liquidity;
            if (newTotal === 0) {
              state = state.deleteIn([
                'placers',
                placerId,
                'betslips',
                betslipId,
                'liquidity',
                'prices',
                price,
              ]);
            } else {
              state = state.setIn(
                ['placers', placerId, 'betslips', betslipId, 'liquidity', 'prices', price, 'total'],
                newTotal
              );
            }
          }
        });
      }
      state = state.deleteIn([
        'placers',
        placerId,
        'betslips',
        betslipId,
        'liquidity',
        'bookies',
        bookieAccountId,
      ]);
      ////////////////////////////////////////////////////////////////////////////////////

      ////////////////////////////////////////////////////////////////////////////////////
      /* Insert in new liquidity data */
      // should only be added if the account from this bookie is selected
      // sometimes pmm's can arrive for unselected bookie accounts - selected here doesn't mean user selected
      // it means the BE select this account on which do do PMMs and provide information on that bookie for the user
      // we have a list of selected accounts already - this handled by the betslip event

      // dont need to check if is selected as we check that below when we calculate the chiclet values

      const priceList = action.data.priceList;
      for (let { effective } of priceList) {
        const price = effective.price;
        const liquidity = Math.round(effective.max[1]); //ignore currencies atm
        state = state.setIn(
          [
            'placers',
            placerId,
            'betslips',
            betslipId,
            'liquidity',
            'bookies',
            bookieAccountId,
            price,
          ],
          liquidity
        );
        state = state.setIn(
          [
            'placers',
            placerId,
            'betslips',
            betslipId,
            'liquidity',
            'prices',
            price,
            bookieAccountId,
          ],
          liquidity
        );
        const oldTotal = state.getIn(
          ['placers', placerId, 'betslips', betslipId, 'liquidity', 'prices', price, 'total'],
          0
        );
        state = state.setIn(
          ['placers', placerId, 'betslips', betslipId, 'liquidity', 'prices', price, 'total'],
          oldTotal + liquidity
        );
      }
      ////////////////////////////////////////////////////////////////////////////////////

      ////////////////////////////////////////////////////////////////////////////////////
      /* Use new info to create the chiclet values */
      // again here we dont need to worry whether the account is in use or not
      // if it is not in use we wont have added any new information and whatever is made here will be correct

      state = resetChiclets(state, betslipId, placerId);

      ////////////////////////////////////////////////////////////////////////////////////

      let oldPriceList = state.getIn([
        'placers',
        placerId,
        'betslips',
        betslipId,
        'accounts',
        betslipAccountId,
        'priceList',
      ]);
      //price list can have prices removed so we need to wholesale replace it
      state = state.setIn(
        ['placers', placerId, 'betslips', betslipId, 'accounts', betslipAccountId, 'priceList'],
        fromJS(action.data.priceList || [])
      );

      //however ... we need to save the inComposition flag
      // inComposition is just a flag used for a css colour in the betslip
      // signifies whether an account is used in a large bet
      // e.g. bet of £3000 at 1.5 highlights all the accounts used in the bet
      // if was prev inComposition then it is now too
      if (oldPriceList) {
        oldPriceList = oldPriceList.toJS();
        for (let pmmKey in oldPriceList) {
          if (oldPriceList[pmmKey] && oldPriceList[pmmKey].inComposition) {
            state = state.setIn(
              [
                'placers',
                placerId,
                'betslips',
                betslipId,
                'accounts',
                betslipAccountId,
                'priceList',
                pmmKey,
                'inComposition',
              ],
              true
            );
          }
        }
      }

      state = state.setIn(
        ['placers', placerId, 'betslips', betslipId, 'accounts', betslipAccountId, 'hasPrices'],
        true
      );
      state = state.setIn(['betslipAccounts', betslipId, betslipAccountId], true);
    } else {
      if (!betslipMessageBuffer[betslipId]) {
        betslipMessageBuffer[betslipId] = [];
      }
      betslipMessageBuffer[betslipId].push(action);
    }

    return state;
  },

  //this function is called when a betslip is updated (may be used for when it's open as well)
  //update will happen based on a websocket message
  //[API]
  betslip: (state, action) => {
    if (config.support.tools.betslipStress) {
      _betslip_count = state.get('openBetslips', 0);
    }

    let data = action.data;
    let betslipId = data.betslipId;
    let placerId = state.getIn(['betslipPlacers', betslipId], 'none');
    //let placer = state.getIn(['placers', placerId], null)
    let betslip = state.getIn(['placers', placerId, 'betslips', betslipId], null);
    let isReady = state.getIn(['placers', placerId, 'betslips', betslipId, 'isReady'], false);
    let disabledBookies = state.getIn(
      ['placers', placerId, 'betslips', betslipId, 'disabledBookies'],
      []
    );

    //we don't like the shape of accounts, it comes in as an array which is hard to work with
    //we want to make it into a map
    let accounts = {};
    let accs = data.accounts;

    for (let idx in accs) {
      let bookieAccountId = `${accs[idx].bookie}/${accs[idx].username}/${accs[idx].betType}`;
      accounts[bookieAccountId] = accs[idx];
      accounts[bookieAccountId].betslipAccountId = bookieAccountId;
      accounts[bookieAccountId].isUsed = state.getIn(
        ['placers', placerId, 'betslips', betslipId, 'accounts', bookieAccountId, 'isUsed'],
        disabledBookies.indexOf(accs[idx].bookie) === -1
      );
      state = state.setIn(['betslipAccounts', betslipId, bookieAccountId], true);
    }

    let chicletsUpdated = false;

    if (!betslip || !isReady) {
      if (!betslipMessageBuffer[betslipId]) {
        betslipMessageBuffer[betslipId] = [];
      }
      betslipMessageBuffer[betslipId].push(action);
    } else {
      //probably a websocket update
      //remove accounts that were on the betslip before but are no longer on the betslip
      let currentAccounts = state.getIn(
        ['placers', placerId, 'betslips', betslipId, 'accounts'],
        null
      );

      // update the data used by the chiclets
      if (currentAccounts) {
        currentAccounts.forEach((acc, bookieAccountId) => {
          // accounts contains the new updated info - we need to change betslip accounts accordingly
          if (!accounts[bookieAccountId]) {
            // update chiclet data
            state = state.removeIn([
              'placers',
              placerId,
              'betslips',
              betslipId,
              'accounts',
              bookieAccountId,
            ]);
            state = state.removeIn(['betslipAccounts', betslipId, bookieAccountId]);
            // the account is no longer selected so we have to remove the price and liquidity from the chiclets
            const prevPrices = state.getIn(
              ['placers', placerId, 'betslips', betslipId, 'liquidity', 'bookies', bookieAccountId],
              null
            );
            if (prevPrices !== null) {
              chicletsUpdated = true;
              prevPrices.mapEntries(([price, liquidity]) => {
                const prevTotal = state.getIn(
                  [
                    'placers',
                    placerId,
                    'betslips',
                    betslipId,
                    'liquidity',
                    'prices',
                    price,
                    'total',
                  ],
                  null
                );
                state = state.deleteIn([
                  'placers',
                  placerId,
                  'betslips',
                  betslipId,
                  'liquidity',
                  'prices',
                  price,
                  bookieAccountId,
                ]);
                if (prevTotal) {
                  const newTotal = prevTotal - liquidity;
                  if (newTotal === 0) {
                    state = state.deleteIn([
                      'placers',
                      placerId,
                      'betslips',
                      betslipId,
                      'liquidity',
                      'prices',
                      price,
                    ]);
                  } else {
                    state = state.setIn(
                      [
                        'placers',
                        placerId,
                        'betslips',
                        betslipId,
                        'liquidity',
                        'prices',
                        price,
                        'total',
                      ],
                      newTotal
                    );
                  }
                }
              });
            }
            state = state.deleteIn([
              'placers',
              placerId,
              'betslips',
              betslipId,
              'liquidity',
              'bookies',
              bookieAccountId,
            ]);

            // handle best account selection - i.e. if that account was the one being used for that bookie we need to replace it or remove the bookie
            const bookieName = acc.get('bookie', '');

            const accountUsedInExchange = state.getIn(
              [
                'placers',
                placerId,
                'betslips',
                betslipId,
                'bestAccountForBookie',
                bookieName,
                'id',
              ],
              null
            );
            // if not the one used then dont need to worry
            if (bookieAccountId === accountUsedInExchange) {
              // if that bookie still has accounts then we will use that data
              // else we will have to delete that bookie from the list
              const bookiesRemainingAccounts = state
                .getIn(['placers', placerId, 'betslips', betslipId, 'accounts'], [])
                .find(
                  (account) =>
                    account.get('bookie') === bookieName &&
                    account.get('hasPrices') === true &&
                    account.get('betslipAccountId') !== bookieAccountId
                );

              if (bookiesRemainingAccounts !== undefined) {
                const newAccount = bookiesRemainingAccounts;
                const newAccountId = newAccount.get('betslipAccountId');
                const newMax = newAccount.getIn(['priceList', 0, 'effective', 'price']);
                state = state.setIn(
                  [
                    'placers',
                    placerId,
                    'betslips',
                    betslipId,
                    'bestAccountForBookie',
                    bookieName,
                    'id',
                  ],
                  newAccountId
                );
                state = state.setIn(
                  [
                    'placers',
                    placerId,
                    'betslips',
                    betslipId,
                    'bestAccountForBookie',
                    bookieName,
                    'max',
                  ],
                  newMax
                );
              } else {
                // bookie may still exist on the other side of the exchange
                state = state.deleteIn([
                  'placers',
                  placerId,
                  'betslips',
                  betslipId,
                  'bestAccountForBookie',
                  bookieName,
                ]);
              }
            }
          }
        });
      }

      // need to recalculate the chiclets info
      if (chicletsUpdated) {
        state = resetChiclets(state, betslipId, placerId);
      }

      // update invalid accounts
      state = state.setIn(
        ['placers', placerId, 'betslips', betslipId, 'invalidAccounts'],
        fromJS({
          ...data.invalidAccounts,
        })
      );

      //preserve as much of the old account data as possible
      state = state.mergeDeepIn(['placers', placerId, 'betslips', betslipId, 'accounts'], accounts);

      //questionable performance wise
      state = tentativePMMComposition(state, placerId, betslipId);

      //we could just use setIn, but me may lose old data
      //in theory this should be ok
    }

    return state;
  },

  //this handles the websocket telling us that a betslip is closed
  //[API]
  betslipClosed: (state, action) => {
    let betslipId = action.data.betslipId;
    let placerId = state.getIn(['betslipPlacers', betslipId], 'none');

    state = state.removeIn(['placers', placerId, 'betslips', betslipId]);
    state = state.removeIn(['betslipPlacers', betslipId]);
    state = state.removeIn(['placerBetslips', placerId, betslipId]);

    //check if the last betslip in placer
    let betslips = state.getIn(['placers', placerId, 'betslips'], null);
    let hasTouchedHandicaps = state.getIn(['placers', placerId, 'hasTouchedHandicaps'], false);

    if (!hasTouchedHandicaps && (!betslips || !betslips.size)) {
      state = state.removeIn(['placers', placerId]);
    }

    let op = state.get('openBetslips', 1) - 1;
    return state.set('openBetslips', op);
  },

  ////// ORDER PLACEMENT

  //this is the order placement function
  //this makes the placement request and handles different types of placement
  betslipPlaceOrder: (state, action) => {
    let betslipId = action.data.betslipId;
    let placerId = state.getIn(['betslipPlacers', betslipId], 'none');
    let betslip = state.getIn(['placers', placerId, 'betslips', betslipId], null);

    if (betslip) {
      let adaptiveBookies = [];
      let accounts = [];

      let bsaccounts = betslip.get('accounts', null);
      if (bsaccounts) {
        bsaccounts.forEach((account) => {
          if (account.get('isUsed', false)) {
            accounts.push([account.get('bookie', '?'), account.get('username', '?')]);
            adaptiveBookies.push(account.get('bookie', '?'));
          }
        });
      }

      //improvise, adapt, overstake
      if (action.data.reselectAccounts === 'only_placement_bookies') {
        //good, we already have that
        adaptiveBookies = _.uniq(adaptiveBookies);
      } else if (action.data.reselectAccounts === 'never') {
        adaptiveBookies = null;
      } else {
        //always
        //nothing
        adaptiveBookies = [];
      }

      let takeStartingPrice = betslip.get('takeStartingPrice', false);
      let keepOpenIr = betslip.get('keepOpenIr', false);

      let body = {
        betslipId,
        price: action.data.price,
        stake: [action.data.customerCcy.toUpperCase(), parseFloat(action.data.stakeCustomer)],

        //get these from user settings maybe?
        ignoreSystemMaintenance: action.data.ignoreSystemMaintenance, //USER_SETTING
        noPutOfferExchange: action.data.noPutOfferExchange, //USER_SETTING
        adaptiveBookies,
        exchangeMode: action.data.goDark ? 'dark' : action.data.exchangeMode,
        accounts,
        requestUuid: v4(),
      };

      if (action.data.goDark) {
        body['minTakerWantStake'] = action.data.darkMin;
      }

      if (takeStartingPrice) {
        //starting price means you can't have a duration
        //or keep open ir
        body['takeStartingPrice'] = takeStartingPrice;
      } else {
        body['duration'] = action.data.timeout;

        if (keepOpenIr) {
          body['keepOpenIr'] = keepOpenIr;
        }
      }

      if (action.data.currentScore) {
        body['currentScore'] = action.data.currentScore;
      }

      DSM.create(
        '/v1/orders/',
        {
          method: 'POST',
          body,
          extras: {
            placerId,
            betslipId,
            stakeGBP: action.data.stakeGBP, //for GA purposes
            price: action.data.price, //for GA purposes
          },
          message: 'betslipPlaceOrderResponse',
        },
        action.data.actions
      );

      //flip the placement flag on so the user can't double click/tap/bash
      state = state.removeIn(['placers', placerId, 'betslips', betslipId, 'placementError']);
      state = state.removeIn(['placers', placerId, 'betslips', betslipId, 'placementErrorData']);
      state = state.setIn(['placers', placerId, 'betslips', betslipId, 'hasPlaced'], false);
      return state.setIn(['placers', placerId, 'betslips', betslipId, 'isPlacing'], true);
    } else {
      return state;
    }
  },

  //place all the betslips in a placer
  placeAllBetslips: (state, action) => {
    let placerId = action.data.placerId;
    let betslips = state.getIn(['placers', placerId, 'betslips'], null);

    if (betslips) {
      state = state.asMutable();
      betslips.forEach((betslip, betslipId) => {
        let price = betslip.get('price', null);
        if (price) {
          price = formatPriceToDecimal(price, action.data.priceType);
        }

        let stake = betslip.get('stakeBetslipCcy', null);
        if (stake) {
          stake = stake.replace(/,/g, '.');
          stake = parseFloat(stake);
        }

        let timeout = betslip.get('timeout', null);
        if (timeout) {
          timeout = parseInt(timeout, 10);
        }

        //no stake, no price or no timeout = skip
        //stripped down of reasonCantPlaceBetslip ?
        if (!stake || !price || !timeout) {
          return;
        }

        let adaptiveBookies = [];
        let accounts = [];

        let bsaccounts = betslip.get('accounts', null);
        if (bsaccounts) {
          bsaccounts.forEach((account) => {
            if (account.get('isUsed', false)) {
              accounts.push([account.get('bookie', '?'), account.get('username', '?')]);
              adaptiveBookies.push(account.get('bookie', '?'));
            }
          });
        }

        //improvise, adapt, overstake
        if (action.data.reselectAccounts === 'only_placement_bookies') {
          //good, we already have that
          adaptiveBookies = _.uniq(adaptiveBookies);
        } else if (action.data.reselectAccounts === 'never') {
          adaptiveBookies = null;
        } else {
          //always
          //nothing
          adaptiveBookies = [];
        }

        let takeStartingPrice = betslip.get('takeStartingPrice', false);
        let keepOpenIr = betslip.get('keepOpenIr', false);

        let body = {
          betslipId,
          price: price,
          stake: [betslip.get('customerCcy', '').toUpperCase(), stake],

          //get these from user settings maybe?
          ignoreSystemMaintenance: action.data.ignoreSystemMaintenance, //USER_SETTING
          noPutOfferExchange: action.data.noPutOfferExchange, //USER_SETTING
          adaptiveBookies,
          accounts,
          requestUuid: v4(),
        };

        if (takeStartingPrice) {
          //starting price means you can't have a duration
          //or keep open ir
          body['takeStartingPrice'] = takeStartingPrice;
        } else {
          body['duration'] = timeout;

          if (keepOpenIr) {
            body['keepOpenIr'] = keepOpenIr;
          }
        }

        if (action.data.currentScore) {
          body['currentScore'] = action.data.currentScore;
        }

        DSM.create(
          '/v1/orders/',
          {
            method: 'POST',
            body,
            extras: {
              placerId,
              betslipId,
            },
            message: 'betslipPlaceOrderResponse',
          },
          action.data.actions
        );

        //flip the placement flag on so the user can't double click/tap/bash
        state = state.removeIn(['placers', placerId, 'betslips', betslipId, 'placementError']);
        state = state.removeIn(['placers', placerId, 'betslips', betslipId, 'placementErrorData']);
        state = state.setIn(['placers', placerId, 'betslips', betslipId, 'hasPlaced'], false);
        state = state.setIn(['placers', placerId, 'betslips', betslipId, 'isPlacing'], true);
      });
      state = state.asImmutable();
    }

    return state;
  },

  //this handles the response from the order placement
  betslipPlaceOrderResponse: (state, action) => {
    let betslipId = action.data.extras.betslipId;
    let placerId = action.data.extras.placerId;
    let betslip = state.getIn(['placers', placerId, 'betslips', betslipId], null);

    if (betslip) {
      state = state.asMutable();
      if (action.data.status !== 'error') {
        //no error
        //record the order the betslip is related to (may be useful in the future)
        state = state.setIn(
          ['placers', placerId, 'betslips', betslipId, 'orders', action.data.data.orderId],
          true
        );
        state = state.setIn(['placers', placerId, 'betslips', betslipId, 'hasPlaced'], true);
      } else {
        //record the error
        state = state.setIn(
          ['placers', placerId, 'betslips', betslipId, 'placementError'],
          action.data.code
        );
        state = state.setIn(
          ['placers', placerId, 'betslips', betslipId, 'placementErrorData'],
          extractErrorMessage(action.data.data)
        );
      }

      //we flip the flag to announce that placing is done
      state = state.setIn(['placers', placerId, 'betslips', betslipId, 'isPlacing'], false);
      state = state.asImmutable();
    }

    return state;
  },

  ////// BETSLIP INTERACTIONS
  // when this is called by the exchange slip - it is called twice - once for back and once for lay
  exchangeEnableBookie: (state, action) => {
    let betslipId = action.data.betslipId;
    let placerId = state.getIn(['betslipPlacers', betslipId], 'none');
    let bookie = action.data.bookie;
    if (!betslipId || !bookie || !placerId) return state;

    state = state.asMutable();

    //mark all accounts with the bookie as unused
    const allAccounts = state.getIn(['placers', placerId, 'betslips', betslipId, 'accounts']);
    allAccounts.keySeq().forEach((accountId) => {
      const accountBookie = accountId.split('/')[0];
      if (accountBookie === bookie) {
        state = state.setIn(
          ['placers', placerId, 'betslips', betslipId, 'accounts', accountId, 'isUsed'],
          true
        );
      }
    });

    //check if the bookie considered as disabled
    let disabledBookies = state.getIn(
      ['placers', placerId, 'betslips', betslipId, 'disabledBookies'],
      []
    );
    //if yes, remove it form the list
    if (disabledBookies.indexOf(bookie) !== -1) {
      disabledBookies.splice(disabledBookies.indexOf(bookie), 1);
      state = state.setIn(
        ['placers', placerId, 'betslips', betslipId, 'disabledBookies'],
        disabledBookies
      );
    }

    ////////////////////////////////////////////////////////////////////////////////////
    /* Update the chiclets - given that a new bookie is availalbe */

    state = resetChiclets(state, betslipId, placerId);

    ////////////////////////////////////////////////////////////////////////////////////

    state = tentativePMMComposition(state, placerId, betslipId);

    return state.asImmutable();
  },

  exchangeDisableBookie: (state, action) => {
    let betslipId = action.data.betslipId;
    let placerId = state.getIn(['betslipPlacers', betslipId], 'none');
    let bookie = action.data.bookie;
    if (!betslipId || !bookie || !placerId) return state;

    state = state.asMutable();

    //mark all accounts with the bookie as unused
    const allAccounts = state.getIn(['placers', placerId, 'betslips', betslipId, 'accounts']);

    allAccounts.keySeq().forEach((accountId) => {
      const accountBookie = accountId.split('/')[0];
      if (accountBookie === bookie) {
        state = state.setIn(
          ['placers', placerId, 'betslips', betslipId, 'accounts', accountId, 'isUsed'],
          false
        );
      }
    });

    //check if the bookie is already considered as disabled
    let disabledBookies = state.getIn(
      ['placers', placerId, 'betslips', betslipId, 'disabledBookies'],
      []
    );
    //if not, add it and mark it so
    if (disabledBookies.indexOf(bookie) === -1) {
      disabledBookies.push(bookie);
      state = state.setIn(
        ['placers', placerId, 'betslips', betslipId, 'disabledBookies'],
        disabledBookies
      );
    }
    //this is a feature that some users *cough*RSL*cough* want because they
    //want accounts from the same bookie to stay disabled when re-added to the betslip

    ////////////////////////////////////////////////////////////////////////////////////
    /* Update the chiclets - given that a new bookie is availalbe */

    state = resetChiclets(state, betslipId, placerId);

    ////////////////////////////////////////////////////////////////////////////////////

    state = tentativePMMComposition(state, placerId, betslipId);

    return state.asImmutable();
  },

  //handle the enabling of a betslip account
  //we have to keep track of this here because it's easier in terms of UPDATE
  betslipEnableAccount: (state, action) => {
    let betslipId = action.data.betslipId;
    let placerId = state.getIn(['betslipPlacers', betslipId], 'none');
    let accountId = action.data.betslipAccountId;

    if (!betslipId || !accountId || !placerId) return state;

    state = state.asMutable();

    //mark the account as used
    state = state.setIn(
      ['placers', placerId, 'betslips', betslipId, 'accounts', accountId, 'isUsed'],
      true
    );

    //check if the bookie considered as disabled
    let disabledBookies = state.getIn(
      ['placers', placerId, 'betslips', betslipId, 'disabledBookies'],
      []
    );
    let bookie = accountId.split('/')[0];
    //if yes, remove it form the list
    if (disabledBookies.indexOf(bookie) !== -1) {
      disabledBookies.splice(disabledBookies.indexOf(bookie), 1);
      state = state.setIn(
        ['placers', placerId, 'betslips', betslipId, 'disabledBookies'],
        disabledBookies
      );
    }

    ////////////////////////////// NEED TO RESET THE CHICLETS

    state = resetChiclets(state, betslipId, placerId);

    ///////////////////////////////////////////

    state = tentativePMMComposition(state, placerId, betslipId);

    return state.asImmutable();
  },

  //handle the disabling of a betslip account
  //we have to keep track of this here because it's easier in terms of UPDATE
  betslipDisableAccount: (state, action) => {
    let betslipId = action.data.betslipId;
    let placerId = state.getIn(['betslipPlacers', betslipId], 'none');
    let accountId = action.data.betslipAccountId;

    if (!betslipId || !accountId || !placerId) return state;

    state = state.asMutable();

    //mark the account as not used
    state = state.setIn(
      ['placers', placerId, 'betslips', betslipId, 'accounts', accountId, 'isUsed'],
      false
    );

    //check if the bookie is already considered as disabled
    let disabledBookies = state.getIn(
      ['placers', placerId, 'betslips', betslipId, 'disabledBookies'],
      []
    );
    let bookie = accountId.split('/')[0];
    //if not, add it and mark it so
    if (disabledBookies.indexOf(bookie) === -1) {
      disabledBookies.push(bookie);
      state = state.setIn(
        ['placers', placerId, 'betslips', betslipId, 'disabledBookies'],
        disabledBookies
      );
    }
    //this is a feature that some users *cough*RSL*cough* want because they
    //want accounts from the same bookie to stay disabled when re-added to the betslip

    ////////////////////////////// NEED TO RESET THE CHICLETS

    state = resetChiclets(state, betslipId, placerId);

    ///////////////////////////////////////////

    state = tentativePMMComposition(state, placerId, betslipId);

    return state.asImmutable();
  },

  //mass enable accounts
  betslipEnableAllAccounts: (state, action) => {
    let betslipId = action.data.betslipId;
    let placerId = state.getIn(['betslipPlacers', betslipId], 'none');
    let accounts = state.getIn(['placers', placerId, 'betslips', betslipId, 'accounts'], null);
    state = state.asMutable();

    //enable each account
    if (accounts) {
      accounts.forEach((account, accountId) => {
        state = state.setIn(
          ['placers', placerId, 'betslips', betslipId, 'accounts', accountId, 'isUsed'],
          true
        );
      });
    }

    //clear betslip disabled bookies
    state = state.setIn(['placers', placerId, 'betslips', betslipId, 'disabledBookies'], []);
    state = tentativePMMComposition(state, placerId, betslipId);

    return state.asImmutable();
  },

  //mass disable accounts
  betslipDisableAllAccounts: (state, action) => {
    let betslipId = action.data.betslipId;
    let placerId = state.getIn(['betslipPlacers', betslipId], 'none');
    let accounts = state.getIn(['placers', placerId, 'betslips', betslipId, 'accounts'], null);
    state = state.asMutable();

    //disable each account
    let disabledBookies = [];
    if (accounts) {
      accounts.forEach((account, accountId) => {
        state = state.setIn(
          ['placers', placerId, 'betslips', betslipId, 'accounts', accountId, 'isUsed'],
          false
        );
        disabledBookies.push(account.get('bookie', ''));
      });
    }

    disabledBookies = _.uniq(disabledBookies);
    //clear betslip disabled bookies
    state = state.setIn(
      ['placers', placerId, 'betslips', betslipId, 'disabledBookies'],
      disabledBookies
    );
    state = tentativePMMComposition(state, placerId, betslipId);

    return state.asImmutable();
  },

  //we make the stake part of the main state otherwise it will get lost on a redraw?
  betslipUpdateStake: (state, action) => {
    state = state.asMutable();
    let betslipId = action.data.betslipId;
    let placerId = state.getIn(['betslipPlacers', betslipId], 'none');

    //update stake
    state = state.setIn(['placers', placerId, 'betslips', betslipId, 'stake'], action.data.stake);
    state = state.setIn(
      ['placers', placerId, 'betslips', betslipId, 'stakeGBP'],
      action.data.stakeGBP || null
    );
    state = state.setIn(
      ['placers', placerId, 'betslips', betslipId, 'stakeBetslipCcy'],
      action.data.stakeBetslipCcy || null
    );

    //mark tentative PMM composition
    state = tentativePMMComposition(state, placerId, betslipId);

    return state.asImmutable();
  },

  //we make the price part of the main state otherwise it will get lost on redraw?
  betslipUpdatePrice: (state, action) => {
    state = state.asMutable();
    let betslipId = action.data.betslipId;
    let placerId = state.getIn(['betslipPlacers', betslipId], 'none');

    //update price
    state = state.setIn(['placers', placerId, 'betslips', betslipId, 'price'], action.data.price);
    //mark tentative PMM composition
    state = tentativePMMComposition(state, placerId, betslipId);
    return state.asImmutable();
  },

  //we make the timeout part of the main state otherwise it will get lost on redraw?
  betslipUpdateTimeout: (state, action) => {
    let betslipId = action.data.betslipId;
    let placerId = state.getIn(['betslipPlacers', betslipId], 'none');
    //update time
    return state.setIn(
      ['placers', placerId, 'betslips', betslipId, 'timeout'],
      action.data.timeout
    );
  },

  //update multiple parameters at the same time
  betslipUpdateParameters: (state, action) => {
    let betslipId = action.data.betslipId;
    let placerId = state.getIn(['betslipPlacers', betslipId], 'none');

    //update generic params
    state = state.mergeDeepIn(['placers', placerId, 'betslips', betslipId], action.data.params);
    return state;
  },

  //change timeouts for all the betslips in a placer
  changeAllBetslipTimeouts: (state, action) => {
    let placerId = action.data.placerId;
    let betslips = state.getIn(['placers', placerId, 'betslips'], null);

    if (betslips) {
      state = state.asMutable();
      betslips.forEach((betslip, betslipId) => {
        state = state.setIn(
          ['placers', placerId, 'betslips', betslipId, 'timeout'],
          action.data.timeout
        );
      });
      state = state.asImmutable();
    }

    return state;
  },

  ////// OTHER UI

  closeMaxBetslipWarning: (state, _action) => {
    return state.set('openBetslipsWarning', false);
  },

  closeThrottledBetslipWarning: (state, _action) => {
    return state.set('throttledBetslipsWarning', false);
  },

  //WE NEED THTIS HERE BECAUSE OF tentativePMMComposition
  //a full list of all the exchange rates
  xratesResponse: (state, action) => {
    if (action.data.status === 'ok') {
      let xrates = action.data.data;
      for (let xrate of xrates) {
        state = state.setIn(['xrates', xrate.ccy.toLowerCase()], xrate.rate);
      }
    }
    return state;
  },

  ////// LOGOUT

  //reset some state
  logout: (state, action) => {
    //get the ids of all the betslips in the placer
    let placers = state.get('placers', null);

    if (placers) {
      placers.forEach((placer, placerId) => {
        let ids = [];
        let betslips = placer.get('betslips', null);

        if (betslips) {
          betslips.forEach((betslip, betslipId) => {
            ids.push(betslipId);
          });
        }

        //for each betslip make a close request to the API
        //this is fire and forget... we will also get close messages down the websockit
        for (let id of ids) {
          state = state.removeIn(['betslipPlacers', id]);
          DSM.create(
            `/v1/betslips/${id}/`,
            {
              method: 'DELETE',
              message: 'betslipCloseResponse',
              ignoreErrors: true, //it's ok to fail
              extras: {
                placerId,
              },
            },
            action.data.actions
          );
        }

        state = state.removeIn(['placerBetslips', placerId]);
      });
    }
    state = state.set('exchanges', fromJS({}));
    return state.set('placers', fromJS({}));
  },
};

export default function reducer(state = initialState, action) {
  let _action = toCamelCase(action.type);
  return functions[_action] ? functions[_action](state, action) : state;
}

export let actions = {};
for (let ct in functions) {
  actions[ct] = (data, noGA, noLog) => ({ type: ct, data, noGA, noLog });
}
