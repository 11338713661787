/** @format */

import DSM from '../lib/DSM';
import { toCamelCase } from '../lib/camelSnake';
import Session from '../lib/Session';
import Whitelabel from '../lib/Whitelabel';
import Logger from '../lib/Logger';
import Telemetry from '../lib/Telemetry';
import * as Sentry from '@sentry/browser';
import PriceFeed from '../lib/PriceFeed';
import config from '../config';
import defaults, { initialWatched } from '../defaults';
import forced from '../forced';
import bowser from 'bowser';
import _ from 'lodash';
import { extractErrorMessage } from '../lib/formatters';
import { clearOldFavs, clearOldExpanded, clearOldUnfavs, getBaseSport } from '../lib/trade';
import { fromJS } from 'immutable';
import ReactGA from 'react-ga';
import { toStdDate } from '../lib/time';
import { formatAmount } from '../lib/formatters';
import DataStream from '../lib/DataStream';
import cookie from 'cookie';

//do a check on the user browser if possible
//we may or may not support it
let _browser;
if (window) {
  _browser = bowser.getParser(window.navigator.userAgent);
}

function generateParams(_filters) {
  let filters = {};

  //put page size in t
  if (_filters['t']) {
    filters['t'] = _filters['t'];
  } else if (_filters['pageSize']) {
    if (_filters['offset']) {
      filters['t'] = _filters['pageSize'] + ':' + _filters['offset'];
      delete _filters['offset'];
    } else {
      filters['t'] = _filters['pageSize'] + ':0';
    }

    delete _filters['pageSize'];
  }

  //put dateFrom and dateTo into q
  if (!_filters['q']) {
    _filters['q'] = '';
  }

  let splitQ = _filters['q'].split('/');
  let _params = [];
  let dateFromFound = false;
  let dateToFound = false;

  for (let idx in splitQ) {
    let atoms = splitQ[idx].split(':');

    if (atoms[0] === 'f' && atoms[1] === 'bookie_date_day') {
      if (atoms[2] === 'ge') {
        dateFromFound = true;
        if (_filters['dateFrom']) {
          atoms[3] = toStdDate(_filters['dateFrom']);
          _filters['dateFrom'] = '';
          _params.push(atoms.join(':'));
        } else if (typeof _filters['dateFrom'] === 'undefined') {
          _params.push(splitQ[idx]);
        }
      } else if (atoms[2] === 'le') {
        dateToFound = true;
        if (_filters['dateTo']) {
          atoms[3] = toStdDate(_filters['dateTo']);
          _filters['dateTo'] = '';
          _params.push(atoms.join(':'));
        } else if (typeof _filters['dateTo'] === 'undefined') {
          _params.push(splitQ[idx]);
        }
      }
    } else {
      _params.push(splitQ[idx]);
    }
  }

  //not found, but exists, must have been added
  if (!dateFromFound && _filters['dateFrom']) {
    _params.push('f:bookie_date_day:ge:' + toStdDate(_filters['dateFrom']));
  }

  //not found, but exists, must have been added
  if (!dateToFound && _filters['dateTo']) {
    _params.push('f:bookie_date_day:le:' + toStdDate(_filters['dateTo']));
  }

  //splitQ = _.compact(splitQ)
  filters['q'] = _params.filter((item) => !!item).join('/');

  //ccy code
  if (_filters['ccyCode']) {
    filters['ccyCode'] = _filters['ccyCode'].toUpperCase();
  }

  return filters;
}

function relativeify(_filters, relative) {
  if (relative === 'allTime') {
    //skip
  } else if (_filters['q']) {
    let splitQ = _filters['q'].split('/');

    for (let idx in splitQ) {
      let atoms = splitQ[idx].split(':');

      if (atoms[0] === 'f' && atoms[1] === 'bookie_date_day') {
        if (atoms[2] === 'ge') {
          atoms[3] = '{from}';
        } else if (atoms[2] === 'le') {
          atoms[3] = '{to}';
        }
      }

      splitQ[idx] = atoms.join(':');
    }

    _filters['q'] = splitQ.join('/');
  }

  return _filters;
}

const BASE_STATE = {
  //user profile information
  //username is stored in a cookie so we can actually get it
  profile: Session.get('profile', {}),
  session: {
    sessionId: Session.get('sessionId', null), //stored in a cookie so we can actually get it
  },

  //because we check upon landing on the page
  isAuth: 'pending',
  //are settings loaded
  isSettingsLoaded: false,
  //are prefs loaded
  isPrefsLoaded: false,

  //all of the settings general and trade
  settings: {},

  //gargoyle
  switches: config.defaultSwitches,

  //exchange rates
  xrates: {},

  //toast messages
  //these are typically errors that are unhandled
  messages: {},

  flags: {
    //has a new release happened (checks against a static file on the server)
    newRelease: false,
    //has the user submitted feedback
    feedbackSent: false,
    //is the browser supported
    isBrowserSupported: _browser ? _browser.satisfies(config.platformSupport) : false,
    //has the customer accepted the fact that they will use an unsupported browsee
    acceptedUnsupportedBrowser: false,
    //are all terms of service accepted
    allTermsAccepted: true,
    //has Google Analytics been initialised
    gaInited: false,
    //connected to          //once logged in need to retrieve ui preferences and customer settings the api and it's working
    apiSync: false,
    //could not connect to the api within a reasonable timeframe
    apiFailedConnect: false,
    //is in the middle of sudoing customer
    isSudoing: false,
  },
  //error on the feedback form
  feedbackError: '',

  //basically we need to prevent the user from seeing the trade page from certain countries
  canPlaceBets: {
    //can the user bet
    canPlaceBets: true,
    //reason why the user can('t) place bets
    reason: '',
    //ip address we think the user has
    ipAddress: '',
    //country we think the user is from based on GEOIP
    country: '',
  },

  //map of agents for each betting account
  accountAgents: {},
  //a list of valid trade page bookies (used for price history and such)
  validTradeBookies: [],
  //a list of valid trade page bookies (used for price history and such)
  validPricesBookies: [],

  //when something uses the core functions it should say its version for the purposes of lognoice and logging
  uiVersion: '0.0.0',
  //what applicaiton is using the core library (ie xena or hera)
  application: 'core',
  //is there a special basename for routes (for the purposes of redirects)
  basename: '',
};

//empty template of the state
let initialState = fromJS(BASE_STATE);

//some basic wrapper for the session logout
function doLogout(state, reason) {
  let sessionId = state.getIn(['session', 'sessionId'], null);

  //let's try?
  console.warn(`Logged out because of: ${reason}`);

  if (sessionId) {
    let ds = DSM.create(`/web/sessions/${sessionId}/`, {
      method: 'DELETE',
      ignoreErrors: true, //we don't care if this fails, the session might not exist
      actions: {}, //to inhibit an error
    });
    //a DataStream with no actions is paused by default

    ds.start();
  }

  //stop logging
  Logger.killStream();

  //remove username and sessionId
  Session.invalidate();

  // remove sonic session id
  let domainsToSet = [];
  if (Whitelabel.cookieDomain) {
    if (typeof Whitelabel.cookieDomain === 'string') {
      domainsToSet = [Whitelabel.cookieDomain];
    } else {
      domainsToSet = Whitelabel.cookieDomain;
    }
  }

  for (let cookieDomain of domainsToSet)
    window.document.cookie = cookie.serialize('session', '', {
      maxAge: 0,
      expire: 0,
      sameSite: 'lax',
      path: '/',
      secure: process.env.NODE_ENV === 'production' || window.location.protocol === 'https:',
      domain: window.location.hostname === 'localhost' ? 'localhost' : cookieDomain,
    });

  Sentry.setUser();

  return state.set('isAuth', 'no');
}

const functions = {
  //be careful when setting the batch parameter on the DataStream, it makes it an array of actions
  data: (state, action) => {
    state = state.asMutable();

    //apply multiple things
    if (action.data.data && typeof action.data.data === 'object') {
      for (let act of action.data.data) {
        state = reducer(state, { type: act[0], data: typeof act[1] !== 'object' ? act : act[1] });
      }
    }

    return state.asImmutable();
  },

  //api stream is proxied so it has to be unpacked
  api: (state, action) => {
    let _data;
    if (action.data.ts) {
      _data = action.data.data;
    } else {
      _data = action.data[0].data;
    }

    if (_data) {
      state = state.asMutable();
      for (let _action of _data) {
        state = reducer(state, { type: _action[0], data: _action[1] });
      }
      state = state.asImmutable();
    }

    return state;
  },

  //do we need to handle any of these ?
  //probably not
  error: (state, action) => {
    console.warn(action.data);
    return state;
  },

  dataError: (state, action) => {
    console.warn(action.data);
    return state;
  },

  wsDataError: (state, action) => {
    console.warn(action.data);
    return state;
  },

  ////// AUTH

  //user confirms that he doesn't care that his browser is not supported
  acceptedUnsupportedBrowser: (state, _action) => {
    return state.setIn(['flags', 'acceptedUnsupportedBrowser'], true);
  },

  //check if user is authenticated
  isUserAuth: (state, action) => {
    //should check if session is still good
    let sessionId = state.getIn(['session', 'sessionId'], null);
    if (!sessionId) {
      //Session.invalidate();
      state = state.set('isAuth', 'no');
    } else {
      DSM.last(
        `/web/sessions/${sessionId}/`,
        {
          method: 'GET',
          message: 'loginResponse',
          errorMessage: 'loginResponse', //we actually want to deal with this
          extras: {
            actions: action.data.actions,
          },
        },
        action.data.actions,
        'isUserAuth'
      );
    }

    return state;
  },

  //login action
  //we need to record the username
  login: (state, action) => {
    let username = action.data.username ? action.data.username.trim() : '';
    Session.set(['profile', 'username'], username);
    return state.setIn(['profile', 'username'], username);
  },

  //login response containing session, user preferences, account info and bookie accounts
  loginResponse: (state, action) => {
    if (action.data && action.data.status === 'ok') {
      //don't expect a session id in session check responses
      //but that's ok
      //state = state.set('isAuth', 'yes');
      //do this in userprefs to lock until everything is here

      //this was intitiated from an actual login
      if (action.data.data.sessionId && action.data.data.username) {
        //we need to store some basic things
        //set basic session information
        Session.set('sessionId', action.data.data.sessionId);

        //full reset
        //we really need username to be in there
        //Session.clear('profile');
        Session.set('profile', action.data.data);

        state = state.setIn(['session', 'sessionId'], action.data.data.sessionId);
        Telemetry.stopRecording('login');

        //logoice
        Logger.setActions(action.data.extras.actions);

        //set loginData
        let loginData = action.data.data;

        ///profie data
        if (loginData.customerData) {
          let customerData = loginData.customerData;

          delete customerData['creditLimit']; //we don't like the format of this
          let _commissionRate = 0;
          if (customerData['commissionRate']) {
            _commissionRate = customerData['commissionRate']['ratePc'];
          } //we don't like the format of this

          state = state.set(
            'profile',
            fromJS({
              ...customerData,
              commissionRate: _commissionRate,
              whitelabel: loginData.whitelabel, //?? no longer used
              lang: loginData.lang, //?? no longer used
              clientType: loginData.clientType,
              sudoer: loginData.sudoer,
              parentSession: loginData.parentSession,
              readonly: loginData.readonly,
              username: loginData.username,
              isAres: loginData.isAres, //?? no longer used
            })
          );

          let userId = state.getIn(['profile', 'id'], null);
          let gaInited = state.getIn(['flags', 'gaInited'], false);

          if (!gaInited && Whitelabel.gaID && userId) {
            ReactGA.set({ userId }, config.support.trackingNames);
            //we did it, or at least tried
            state = state.setIn(['flags', 'gaInited'], true);
          }
        }

        //can see trade
        if (loginData.canPlaceBets) {
          let canPlaceBets = loginData.canPlaceBets;
          state = state.set('canPlaceBets', fromJS(canPlaceBets));
        }

        //once logged in need to retrieve ui preferences and customer settings
        DSM.last(
          `/web/preferences/${action.data.data.username}/uipreferences/`,
          {
            method: 'GET',
            message: 'handlePrefs',
          },
          action.data.actions,
          'uipreferences'
        );

        //get customer settings (i.e. things they can't control)
        DSM.last(
          `/web/preferences/${action.data.data.username}/customersettings/`,
          {
            method: 'GET',
            message: 'handleSettings',
            extras: {
              username: action.data.data.username,
              actions: action.data.actions,
              sessionId: action.data.data.sessionId,
            },
            body: {
              feat: config.featureFlags,
            },
          },
          action.data.actions,
          'customersettings'
        );

        // Redirect to sonic if whitelabel has setting and user doesnt have preference
        if (
          Whitelabel.sonicRedirect &&
          !window.location.host.startsWith('m.') &&
          !window.location.host.startsWith('mgoal.') &&
          !window.location.host.startsWith('xena.') &&
          !window.location.host.startsWith('m-doge.') &&
          !window.location.pathname.startsWith('/agent') &&
          !window.location.pathname.startsWith('/admin') &&
          !window.location.pathname.startsWith('/history') &&
          !state.getIn(['profile', 'sudoer'])
        ) {
          fetch(`/web/newpreferences/${action.data.data.username}/uipreferences/`, {
            headers: { session: action.data.data.sessionId },
          })
            .then((response) => response.json())
            .then((reponse) => {
              if (!reponse.data['global.preferLegacy']) {
                window.location.href = Whitelabel.sonicUrl;
              }
            });
        }
      }

      return state;
    } else {
      return doLogout(state, 'login_rejected');
    }
  },

  handleBookieAccounts: (state, action) => {
    //trying to determine what bookies are actually exposed to the customer
    if (action.data.status === 'ok' && action.data.data) {
      let accounts = action.data.data;
      let agents = {};
      let validTradeBookies = [];
      let validPricesBookies = [];
      let totalBalance = 0;
      let xrates = state.get('xrates');
      let ccyCode = state.getIn(['profile', 'ccyCode'], '?').toLowerCase();

      for (let account of accounts) {
        agents[account.username] = account.agent;

        totalBalance += account.bookieBalance
          ? parseFloat(
              formatAmount(
                account.bookieBalance[1],
                account.bookieBalance[0],
                ccyCode,
                xrates,
                false,
                true
              )
            )
          : 0;

        if (account.enabled) {
          validPricesBookies.push(account.bookie);
        }
        validTradeBookies.push(account.bookie);
      }

      state = state.setIn(['profile', 'totalBookieBalance'], totalBalance);

      state = state.set('accountAgents', fromJS(agents));
      //forcefully add some bookies
      validPricesBookies = validPricesBookies.concat(config.settingsOptions.priceHistoryBookies);

      //HACK (this needs to be filtered out better)
      validPricesBookies = _.uniq(validPricesBookies).filter(
        (bookie) =>
          !bookie.startsWith('broker') && bookie.indexOf('dummy') === -1 && bookie !== 'totusbet'
      );
      validTradeBookies = _.uniq(validTradeBookies).filter(
        (bookie) =>
          !bookie.startsWith('broker') && bookie.indexOf('dummy') === -1 && bookie !== 'totusbet'
      );

      state = state.set('validTradeBookies', validTradeBookies.sort());
      state = state.set('validPricesBookies', validPricesBookies.sort());
    }

    return state;
  },

  handlePrefs: (state, action) => {
    if (action.data.status === 'ok') {
      let userprefs = action.data.data;
      const isFirstLogin = !Object.keys(userprefs).length;

      //get settings data
      if (userprefs) {
        //process settings
        //add defaults, then whitelabel defaults, then the user's actual preferences, then whitelabel locked preferences
        //migrate pref location
        if (
          userprefs['trade'] &&
          !userprefs['trade']['quickPlaceButtonOneValue'] &&
          userprefs['general'] &&
          userprefs['general']['quickPlaceButtonOneValue']
        ) {
          userprefs['trade']['quickPlaceButtonOneValue'] =
            userprefs['general']['quickPlaceButtonOneValue'];
          userprefs['trade']['quickPlaceButtonTwoValue'] =
            userprefs['general']['quickPlaceButtonTwoValue'];
          userprefs['trade']['quickPlaceButtonThreeValue'] =
            userprefs['general']['quickPlaceButtonThreeValue'];
        }

        let toSet = {};

        if (isFirstLogin) {
          toSet.trade = toSet.trade || {};
          toSet.trade.watched = initialWatched;
          toSet.trade.expanded = {
            main: { markets: { favs: true, ir: true, today: true, early: true } },
            side: { markets: { favs: true, ir: true, today: true, early: true } },
          };
        }
        //merge settings, so stuff that is new is added automatically
        _.merge(toSet, defaults, Whitelabel.defaults, userprefs, Whitelabel.locked);

        //fix prices bookies
        if (userprefs['trade'] && userprefs['trade']['pricesBookies']) {
          toSet['trade']['pricesBookies'] = userprefs.trade.pricesBookies;
        }

        if (toSet['trade']['enabledGroups']?.darts == null) {
          toSet['trade']['enabledGroups']['darts'] = defaults.trade.enabledGroups['darts'];
        }

        //fix sport if broken?
        //this code may not be required anymore, some users had a broken sport at some point
        if (
          toSet['trade'] &&
          toSet['trade']['sport'] &&
          !config.sportNames[toSet['trade']['sport']]
        ) {
          toSet['trade']['sport'] = 'fb';
        }

        //fix wierd timezones that the backend can't support
        if (
          toSet['general'] &&
          toSet['general']['timezone'] &&
          (toSet['general']['timezone'].indexOf('GMT') === 0 ||
            toSet['general']['timezone'].indexOf('Etc') === 0)
        ) {
          toSet['timezone'] = 'UTC';
          toSet['general']['timezone'] = 'UTC';
        }

        //we also have to deal with forced settings
        let forceSettings =
          !(userprefs.general && userprefs.general.lastForced) ||
          new Date(forced.date) > new Date(userprefs.general.lastForced);
        //exceptions :/
        if (forceSettings) {
          _.merge(toSet, forced.settings, { general: { lastForced: +new Date() } });
          if (forced.settings.trade) {
            if (forced.settings.trade.pricesBookies) {
              toSet['trade']['pricesBookies'] = forced.settings.trade.pricesBookies;
            }
            if (forced.settings.trade.disabledBookies) {
              toSet['trade']['disabledBookies'] = forced.settings.trade.disabledBookies;
            }
          }
        }

        Session.loadSettings(toSet);

        //enable lognoice if forced by admin
        if (Session.get(['settings', 'general', 'lognoiceEnabledByAdmin'], false)) {
          Logger.initStream(Session.get('sessionId'));
          Logger.setEnabled(true);
        }

        //clean up some old stuff
        clearOldFavs(Session);
        clearOldUnfavs(Session);
        clearOldExpanded(Session);

        //start with early closed because it's too heavy usually
        state = state.setIn(['settings', 'trade', 'expanded', 'side', 'markets', 'early'], false);
        state = state.setIn(['settings', 'trade', 'expanded', 'main', 'markets', 'early'], false);

        state = state.set('isPrefsLoaded', true);
        //if other flag is loaded too
        if (state.get('isSettingsLoaded')) {
          state = state.set('isAuth', 'yes');
          //update settings to whatever Session settings ends up being
          state = state.set('settings', fromJS(Session.get('settings', {})));
          Session.markInited();
        }
      }
    } else {
      //Session.invalidate()
      state = doLogout(state, 'failed_prefs');
    }

    return state;
  },

  handleSettings: (state, action) => {
    if (action.data.status === 'ok') {
      //separate out permissions from prefs

      let permissions = action.data.data.permissions || {};

      state = state.mergeDeepIn(['profile', 'config'], {
        webPlaceBets: permissions.webPlaceBets,

        superuser: permissions.admin,
        masterAgent: permissions.masterAgent,
        subAgent: permissions.subAgent,
        limitedAdmin: permissions.limitedAdmin,
        agent: permissions.subAgent || permissions.masterAgent,

        canCreateUsers: permissions.canCreateUsers,
        canSeeTrade: permissions.canSeeTrade,
        canChangeCreditLimits: permissions.canChangeCreditLimits,
        canChangeNetPrices: permissions.canChangeNetPrices,
        canCreateTransfers: permissions.canCreateTransfers,
        canUseMultipleAccounts: permissions.canUseMultipleAccounts,
        sudo: permissions.sudo,
        netPrices: permissions.netPrices,
        seeAccounts: permissions.seeAccounts,
        spyOnGroup: permissions.canSpyOnGroup,
        seePt: permissions.seePt,
        smartCredit: permissions.smartCredit,
        //roles: permissions.roles,

        maxBetslips: action.data.data.maxBetslips,
        maxOrder: action.data.data.maxOrder,
      });

      //switches
      let features = action.data.data.features;
      if (features) {
        state = state.set('switches', fromJS(features));
        //init logger stream
        //features.lognoice = true;
        if (!!features.lognoice) {
          Logger.initStream(Session.get('sessionId'));
          Logger.setEnabled(!!features.lognoice);
        }
      }

      Session.set(['settings', 'terms'], action.data.data.terms || {});

      //check if you have unaccepted terms
      let hasUnacceptedTerms = false;
      let termsDict = {};
      if (Whitelabel.terms) {
        termsDict =
          Whitelabel.terms[state.getIn(['profile', 'groupId'], 'all')] || Whitelabel.terms['all'];
      }
      for (let terms in termsDict) {
        let d = new Date(Session.get(['settings', 'terms', terms], 0));
        let td = new Date(termsDict[terms].lastUpdated);
        if (d < td) {
          hasUnacceptedTerms = true;
        }
      }

      //set flag if user has accepted all terms
      if (hasUnacceptedTerms) {
        state = state.setIn(['flags', 'allTermsAccepted'], false);
      }

      state = state.set('isSettingsLoaded', true);
      //if other flag is loaded too
      if (state.get('isPrefsLoaded')) {
        state = state.set('isAuth', 'yes');
        //update settings to whatever Session settings ends up being
        state = state.set('settings', fromJS(Session.get('settings', {})));
        Session.markInited();
      }
    } else {
      //Session.invalidate()
      //hopefully doesn't race with the above
      state = doLogout(state, 'failed_settings');
    }

    return state;
  },

  //logout action
  logout: (state, _action) => {
    return doLogout(state, 'logout');
  },

  ////// APPLICATION

  //this sets the ui version which is used
  //this comes from package.json when building
  setUIVersion: (state, action) => {
    state = state.set('uiVersion', action.data.version);
    state = state.set('application', action.data.application);
    Telemetry.record('version', action.data.version);
    Logger.setApplication(`${action.data.application} ${action.data.version}`);
    return state.set('basename', action.data.basename);
  },

  //this raises a flag that the application has been updated
  applicationUpdated: (state, action) => {
    if (action.data.status === 'ok') {
      Telemetry.stopRecording('sync'); //we piggy back on this request to see how long it takes to make a simple GET of static content
      let next = action.data.data.version.split('.');
      let curr = state.get('uiVersion').split('.');

      if (next.length !== curr.length) {
        state = state.setIn(['flags', 'newRelease'], action.data.data.version);
      } else {
        //semanti-ish version check
        for (let i = 0; i < next.length; i++) {
          if (parseInt(next[i], 10) !== parseInt(curr[i], 10)) {
            if (parseInt(curr[i], 10) > parseInt(next[i], 10)) {
              //current is newer, just break
              break;
            } else {
              //next is newer, warn and break
              state = state.setIn(['flags', 'newRelease'], action.data.data.version);
              break;
            }
          }
        }
      }
    }

    return state;
  },

  //this handles an application reload
  //this is a forceful reload, the user has no choice
  //this is used in emergencies
  reloadApplication: (state, _action) => {
    if (window) {
      setTimeout(() => {
        window.location.reload();
      }, config.timings.reloadAferChunkFailure);
    }
    return state;
  },

  //close an interface (react controlled) toast
  closeMessage: (state, action) => {
    return state.removeIn(['messages', action.data.id]);
  },

  ////// API STREAM

  //initiate the api stream connection
  baseStreamConnect: (state, action) => {
    Sentry.setUser({
      username: action.data.currentUser,
    });

    //create the API stream
    PriceFeed.start(
      {
        sessionId: action.data.sessionId,
        language: action.data.language,
        bookies: action.data.bookies,
        monitorHeartBeats: action.data.monitorHeartBeats,
        recover: action.data.recover,
      },
      action.data.actions
    );

    DSM.ensureOne(
      `/v1/xrates/`,
      {
        method: 'GET',
        message: 'xratesResponse',
        interval: config.timings.xratesRefresh,
        extras: {
          username: action.data.currentUser,
          actions: action.data.actions,
        },
      },
      action.data.actions,
      'xratesStream'
    );

    //create the user accounting information stream
    DSM.ensureOne(
      `/v1/customers/${action.data.currentUser}/accounting_info/`,
      {
        message: 'accountingResponse',
        method: 'GET',
        interval: config.timings.plInfo,
        ignoreErrors: true, //adriano wants this suppressed even if it's a valid error
      },
      action.data.actions,
      'plStream'
    );

    //create the application version check stream
    DSM.ensureOne(
      `/version.json`,
      {
        message: 'applicationUpdated',
        method: 'GET',
        interval: config.timings.versionCheckInterval,
        ignoreErrors: true, //we care, but not that much...
      },
      action.data.actions,
      'versionStream'
    );
    Telemetry.startRecording('sync', 'sync_latency');

    return state;
  },

  //this does stream disconnection cleanup
  //in theory we don't need to do this since we're leaving the page
  baseStreamDisconnect: (state, _action) => {
    PriceFeed.stop();
    DSM.stop('xratesStream');
    DSM.stop('bookieAccountsStream');
    DSM.stop('plStream');
    DSM.stop('statusStream');
    DSM.stop('versionStream');

    //reset the flags at least
    state = state.set('flags', fromJS(BASE_STATE.flags));

    return state;
  },

  //what happens when the API stream fails to connect
  baseStreamFailedConnect: (state, _action) => {
    state = state.setIn(['flags', 'apiSync'], false); //we are no longer connected to the api
    state = state.setIn(['flags', 'apiFailedConnect'], true); //we have failed to connect

    return state;
  },

  //what happens when the API manages to recover connection
  baseStreamRecoveredConnect: (state, action) => {
    state = state.setIn(['flags', 'apiSync'], true); //we are connected to the api

    //if the recovery flag is passed then we have to reload the page to ensure sync
    //recovery flag is mostly dictated by how long the connection has been lost (check DataStream)
    //this is a brute force approace because it's complicated to recover correctly
    if (window && action.data.recover) {
      setTimeout(function () {
        window.location.reload();
      }, config.timings.baseRecoveryReload + Math.random() * config.timings.recoveryReloadSpread);
    }
    return state;
  },

  //what happens when the API has successfully connected
  baseStreamSuccessConnect: (state, _action) => {
    state = state.setIn(['flags', 'apiSync'], true); //mark as connected to
    state = state.setIn(['flags', 'apiFailedConnect'], false); //reset API connection failure

    return state;
  },

  //what happens when too many heartbeats have been missed
  baseStreamHeartbeatMissed: (state, _action) => {
    state = state.setIn(['flags', 'apiSync'], false); //mark as not connected to api
    return state;
  },

  //do we have a session expiration message
  //this comes from the api stream usually
  //[API]
  sessionExpired: (state, _action) => {
    //automatic logout
    //bad idea apparently
    //return doLogout(state, 'session_expired');
    return state;
  },

  //update balances from the order stream message
  //[API]
  balance: (state, action) => {
    let creditLimit = state.getIn(['profile', 'creditLimit'], 0);
    if (isNaN(creditLimit)) {
      creditLimit = 0;
    }

    let newData = {
      openStakes: action.data.openStake[1],
      balance: action.data.balance[1],
      smartCredit: action.data?.smartCredit?.[1],
      credit: creditLimit + action.data.balance[1] - action.data.openStake[1],
    };

    if (state.getIn(['base', 'switches', 'smartCredit'], false) && newData.smartCredit) {
      newData.credit += newData.smartCredit;
    }

    return state.mergeDeepIn(['profile'], newData);
  },

  //respond to exchange rate change
  //this is actually very expensive so we ignore it because it won't impact UX much
  //[API]
  xrate: (state, _action) => {
    //let xrate = action.data
    //return state.setIn(['xrates', xrate.ccy.toLowerCase()], xrate.rate)
    return state;
  },

  ////// PROFILE

  //deal with accounting information data
  accountingResponse: (state, action) => {
    if (action.data.status === 'ok') {
      //we need to map the info a bit
      let profileName = {
        current_balance: 'balance',
        open_stakes: 'openStakes',
        today_pl: 'todayPl',
        yesterday_pl: 'yesterdayPl',
        credit_limit: 'creditLimit',
        available_credit: 'credit',
        commission_rate: 'commissionRate',
        smart_credit: 'smartCredit',
      };

      let creditLimit = 0; //assume unlimited
      let balance = 0;
      let openStakes = 0;
      let smartCredit = 0;

      state = action.data.data.reduce((_state, item) => {
        if (item.key in profileName) {
          _state = _state.setIn(['profile', profileName[item.key]], item.value);
          if (item.key === 'credit_limit') {
            creditLimit = parseFloat(item.value) || 0;
          }
          if (item.key === 'current_balance') {
            balance = parseFloat(item.value) || 0;
          }
          if (item.key === 'open_stakes') {
            openStakes = parseFloat(item.value) || 0;
          }
          if (
            item.key === 'smart_credit' &&
            state.getIn(['base', 'switches', 'smartCredit'], false)
          ) {
            smartCredit = parseFloat(item.value) || 0;
          }
        }
        return _state;
      }, state);

      // because the world is a sad place full of pain an betrayal (MF-358)
      state = state.setIn(['profile', 'credit'], creditLimit + balance - openStakes + smartCredit);
    }

    return state;
  },

  //a full list of all the exchange rates
  xratesResponse: (state, action) => {
    if (action.data.status === 'ok') {
      let xrates = action.data.data;
      for (let xrate of xrates) {
        state = state.setIn(['xrates', xrate.ccy.toLowerCase()], xrate.rate);
      }
    }

    //there is a dependancy on having xrates
    //get customer bookie accounts
    DSM.ensureOne(
      `/v1/customers/${action.data.extras.username}/bookie_accounts/`,
      {
        method: 'GET',
        message: 'handleBookieAccounts',
        interval: config.timings.bookieAccountRefresh,
      },
      action.data.extras.actions,
      'bookieAccountsStream'
    );

    return state;
  },

  removeBetsView: (state, action) => {
    new DataStream(`/web/preferences/${action.data.username}/uipreferences/`, { method: 'GET' })
      .start()
      .then((response) => response.json())
      .then((data) => {
        const settings = state.get('settings', null);
        const views = data.data.history.bets.views;
        delete views[action.data.id];
        if (settings) {
          // Actually save the new settings to the session
          // which will also send them to a backend (in a debounced manner).
          // Set it from freshly fetched views to try overcome multi-login accounts
          // overriding each others saved views.
          Session.set(
            'settings',
            settings.setIn(['settings', 'history', 'bets', 'views'], fromJS(views))
          );
        }
      });

    state = state.removeIn(['settings', 'history', 'bets', 'views', action.data.id]);
    return state;
  },

  saveBetsView: (state, action) => {
    const params = action.data.params;
    const query = action.data.data.relative
      ? relativeify(generateParams(params.toJS()), action.data.relative)
      : generateParams(params.toJS());
    const view = { ...action.data.data, query };
    const id = action.data.data.name;

    new DataStream(`/web/preferences/${action.data.data.username}/uipreferences/`, {
      method: 'GET',
    })
      .start()
      .then((response) => response.json())
      .then((data) => {
        const settings = state.get('settings', null);
        const views = { ...data.data.history.bets.views, [id]: view };
        if (settings) {
          // Actually save the new settings to the session
          // which will also send them to a backend (in a debounced manner).
          // Set it from freshly fetched views to try overcome multi-login accounts
          // overriding each others saved views.
          Session.set(
            'settings',
            settings.setIn(['settings', 'history', 'bets', 'views'], fromJS(views))
          );
        }
      });

    state = state.setIn(['settings', 'history', 'bets', 'views', id], fromJS(view));
    return state;
  },

  ////// SETTINGS

  //this is a generic way of applying interface and settings changes
  applySettings: (state, action) => {
    //basically overwrite all the settings
    if (action.data) {
      state = state.mergeDeepIn(['settings'], action.data);
    }

    //wonderful exceptions
    if (action.data && action.data['trade']) {
      if (action.data['trade']['pricesBookies']) {
        state = state.setIn(
          ['settings', 'trade', 'pricesBookies'],
          fromJS(action.data['trade']['pricesBookies'])
        );
      }
      if (action.data['trade']['disabledBookies']) {
        state = state.setIn(
          ['settings', 'trade', 'disabledBookies'],
          fromJS(action.data['trade']['disabledBookies'])
        );
      }
    }

    if (action.data && action.data['history']) {
      if (action.data['history']['bets'] && action.data['history']['bets']['views']) {
        state = state.setIn(
          ['settings', 'history', 'bets', 'views'],
          fromJS(action.data['history']['bets']['views'])
        );
      }
    }

    let _settings = state.get('settings', null);
    if (_settings) {
      //actually save the new settings to the session
      //which will also send them to a backend (in a debounced manner)
      Session.set('settings', _settings);
    }

    return state;
  },

  //hard reset on all the user settings
  //this applies all the defaults, but saves language and accepted terms
  resetSettings: (state, _action) => {
    let toSet = {};
    let saveSome = {
      general: {
        language: Session.get(['settings', 'general', 'language'], 'en'),
      },
      terms: Session.get(['settings', 'terms'], {}),
    };
    //merge defaults, some saved stuff, defaults, forced whitelabel settings, and recent forced settings
    _.merge(toSet, defaults, saveSome, Whitelabel.defaults, Whitelabel.locked, forced.settings);
    Session.loadSettings(toSet);
    return state.set('settings', fromJS(toSet));
  },

  ////// COMPETITIONS

  //this subscribes to a competition (used by the left hand side menu)
  competitionSubscribe: (state, action) => {
    if (action.data.silent) {
      //don't save
      return state;
    }

    let compId = action.data.competitionId;
    let marketId = action.data.marketId;
    let sport = action.data.sport;
    let baseSport = getBaseSport(sport);

    //set subscribed in session
    Session.set(
      ['settings', 'trade', 'watched', 'competitions', marketId, compId, 'time'],
      +new Date()
    );
    Session.set(
      ['settings', 'trade', 'watched', 'competitions', marketId, compId, 'sport'],
      baseSport
    );

    //expand main makret
    Session.set(['settings', 'trade', 'expanded', 'main', 'markets', marketId], true);
    state = state.setIn(['settings', 'trade', 'expanded', 'main', 'markets', marketId], true);
    return state;
  },

  //try to unsubscribe from an competition
  competitionUnsubscribe: (state, action) => {
    let compId = action.data.competitionId;
    let marketId = action.data.marketId;

    //save session
    Session.clear(['settings', 'trade', 'watched', 'competitions', marketId, compId]);
    state = state.removeIn(['settings', 'trade', 'watched', 'competitions', marketId, compId]);
    return state;
  },

  //try to unsubscribe from an competition
  competitionUnsubscribeAll: (state, _action) => {
    Session.clear(['settings', 'trade', 'watched', 'competitions']);
    return state;
  },

  //add a competition to favorites
  competitionAddFav: (state, action) => {
    let compId = action.data.competitionId;
    let _sport = action.data.sport;

    Session.set(['settings', 'trade', 'faved', 'competitions', compId], true);
    //is this really required
    state = state.setIn(['settings', 'trade', 'faved', 'competitions', compId], true);

    return state;
  },

  //remove a competition from favorites
  competitionRemoveFav: (state, action) => {
    let compId = action.data.competitionId;

    Session.clear(['settings', 'trade', 'faved', 'competitions', compId]);
    return state.removeIn(['settings', 'trade', 'faved', 'competitions', compId]);
  },

  //this handles the adding of the event to favs from a settings pov
  eventAddFav: (state, action) => {
    let eventId = action.data.eventId; //event
    let competitionId = action.data.competitionId; //competition
    let sport = action.data.sport; //sport

    //add to session favs
    Session.set(['settings', 'trade', 'faved', 'events', eventId], true);
    //remove unfav if there is one
    Session.clear(['settings', 'trade', 'unfaved', 'events', eventId]);
    state = state.removeIn(['settings', 'trade', 'unfaved', 'events', eventId]);

    //is this really required?
    state = state.mergeDeepIn(['settings', 'trade', 'faved', 'events', eventId], {
      competitionId,
      sport,
    });
    return state;
  },

  //remove an event from favorites from a settings pov
  eventRemoveFav: (state, action) => {
    let eventId = action.data.eventId; //event

    //remove from session favs
    Session.clear(['settings', 'trade', 'faved', 'events', eventId]);
    state = state.removeIn(['settings', 'trade', 'faved', 'events', eventId]);

    //add to unfav
    Session.set(['settings', 'trade', 'unfaved', 'events', eventId], true);
    state = state.setIn(['settings', 'trade', 'unfaved', 'events', eventId], true);

    return state;
  },

  //expand the extra sport markets for event
  eventExpandExtras: (state, action) => {
    let eventId = action.data.eventId; //event
    Session.set(['settings', 'trade', 'hasExtrasExpanded', eventId], true);
    return state;
  },

  //contract the extra sport markets for event
  eventCloseExtras: (state, action) => {
    let eventId = action.data.eventId; //event
    Session.clear(['settings', 'trade', 'hasExtrasExpanded', eventId]);
    return state;
  },

  //FEEDBACK
  sendFeedback: (state, action) => {
    state = state.setIn(['flags', 'feedbackSent'], false);
    state = state.set('feedbackError', '');

    DSM.last(
      '/web/mail/send_feedback/',
      {
        method: 'POST',
        message: 'feedbackConfirm',
        body: {
          message: action.data.message,
          feedback_type: action.data.type,
          location: window ? window.location.pathname : '',
          device: window ? window.navigator.userAgent : '',
          contactEmail: Whitelabel.feedbackEmail,
        },
      },
      action.data.actions,
      'sendFeedback'
    );

    return state.setIn(['flags', 'feedbackSending'], true);
  },

  //the response to the sent feedback
  feedbackConfirm: (state, action) => {
    state = state.setIn(['flags', 'feedbackSending'], false);
    state = state.setIn(['flags', 'feedbackSent'], true);

    if (action.data.status === 'ok') {
      return state;
    } else {
      return state.set('feedbackError', action.data.code);
    }
  },

  ////// TERMS

  //accept a portion of terms
  acceptTerms: (state, action) => {
    if (action.data.terms) {
      let accepted = +new Date();
      Session.set(['settings', 'terms', action.data.terms], accepted);
      state = state.setIn(['settings', 'terms', action.data.terms], accepted);
    }

    //check if you have unaccepted terms
    let hasUnaccepted = false;
    let termsDict =
      Whitelabel.terms[state.getIn(['profile', 'groupId'], 'all')] || Whitelabel.terms['all'] || {};
    for (let terms in termsDict) {
      let d = new Date(Session.get(['settings', 'terms', terms], 0));
      let td = new Date(termsDict[terms].lastUpdated);
      if (d < td) {
        hasUnaccepted = true;
      }
    }

    return state.setIn(['flags', 'allTermsAccepted'], !hasUnaccepted);
  },

  //unaccept a portion of terms
  unacceptTerms: (state, action) => {
    if (action.data.terms) {
      Session.clear(['settings', 'terms', action.data.terms]);
      state = state.removeIn(['settings', 'terms', action.data.terms]);
      return state.setIn(['flags', 'allTermsAccepted'], false);
    } else {
      return state;
    }
  },

  ////// STATS

  //record sync latency for data streams
  recordLatencyStats: (state, _action) => {
    let country = state.getIn(['canPlaceBets', 'country'], '?');
    let ip = state.getIn(['canPlaceBets', 'ipAddress'], '?');
    console.warn(`Stats ----------`);
    console.warn(`Application: ${state.get('application')}`);
    console.warn(`Version: ${state.get('uiVersion')}`);
    console.warn(`Location: ${country} / ${ip}`);
    try {
      if (window && window.navigator) {
        console.warn(`Device: ${window.navigator.platform} / ${window.navigator.userAgent}`);

        if (window.navigator.connection) {
          console.warn(`Connection: ${window.navigator.connection.effectiveType}`);
        }
      }
    } catch (err) {
      //it's ok...
      //Sentry.captureException(err);
    }
    if (window && window._DATASTREAMS) {
      for (let uid in window._DATASTREAMS) {
        if (window._DATASTREAMS[uid]._isWs) {
          //in theory lognoice should pick this up
          console.warn(
            `[RTT ${window._DATASTREAMS[uid].lastRTT()}ms][CONN ${window._DATASTREAMS[
              uid
            ].wsConnectTime()}ms][WS] ${window._DATASTREAMS[uid]._shortName}`
          );
        }
        if (window._DATASTREAMS[uid].params.interval) {
          console.warn(
            `[RTT ${window._DATASTREAMS[uid].lastRTT()}ms][${
              window._DATASTREAMS[uid].params.method
            }] ${window._DATASTREAMS[uid]._shortName}`
          );
        }
      }
    }

    return state;
  },

  //sudo a customer by making a special weblogin response
  //base reducer handles what actually happens since it's similar to a LOGIN
  sudoCustomer: (state, action) => {
    if (action.data.sessionId && action.data.target) {
      DSM.last(
        `/web/sessions/${action.data.sessionId}/sudo/`,
        {
          method: 'POST',
          body: {
            target: action.data.target,
          },
          extras: {
            target: action.data.target,
            redirectUrl: action.data.redirectUrl,
          },
          message: 'sudoedCustomer', //handled in base
        },
        action.data.actions,
        'sudoCustomer'
      );

      state = state.setIn(['flags', 'isSudoing'], true);
    }

    return state;
  },

  //need to refresh when unsudoing a customer
  //have to also do some cleanup
  unsudoCustomer: (state, action) => {
    let _oldSession = state.getIn(['profile', 'parentSession'], null);
    let sudoer = state.getIn(['profile', 'sudoer']);
    if (_oldSession) {
      Logger.killStream(); //kill logger
      Session.invalidate();
      Session.set('sessionId', _oldSession);
      Session.set(['profile', 'username'], sudoer);

      //not required?
      state = state.setIn(['session', 'sessionId'], _oldSession);
      state = state.setIn(['profile', 'username'], sudoer);

      setTimeout(() => {
        window.location.href = action.data.redirectUrl; //bah!
      }, config.timings.sudoRefreshDelay);
    }
    return state;
  },

  //need to refresh when unsudoing a customer
  //have to also do some cleanup
  sudoedCustomer: (state, action) => {
    if (action.data.status === 'ok') {
      Logger.killStream(); //kill logger
      Session.invalidate(); //wipe session
      Session.set('sessionId', action.data.data.sessionId); //new session
      Session.set(['profile', 'username'], action.data.extras.target); //new user

      //not required?
      state = state.setIn(['session', 'sessionId'], action.data.data.sessionId);
      state = state.setIn(['profile', 'username'], action.data.extras.target);

      //flip flag

      setTimeout(() => {
        window.location.href = action.data.extras.redirectUrl; //bah!
      }, config.timings.sudoRefreshDelay);
      return state;
    } else {
      //errors handled in base automatically
      return state.setIn(['flags', 'isSudoing'], false);
    }
  },

  ////// REQUESTING TOKEN FOR EXTERNAL REDIRECT

  requestToken: (state, action) => {
    let sessionId = Session.get('sessionId');
    let redirectUrl = action.data.url;
    let lang = action.data.lang;
    DSM.last(
      `/v1/sessions/${sessionId}/tokenize/`,
      {
        method: 'POST',
        message: 'requestTokenResponse',
        extras: {
          redirectUrl,
          lang,
        },
      },
      action.data.actions,
      'requestToken'
    );

    return state;
  },

  requestTokenResponse: (state, action) => {
    if (action.data.status === 'ok') {
      let lang = action.data.extras.lang;
      let token = action.data.data;

      if (token) state = state.setIn(['profile', 'externalURLsToken'], token);
      if (lang) state = state.setIn(['profile', 'externalURLsLang'], lang);
    }

    return state;
  },

  // temporary action used for sonic announcements
  navigateUserToSonic: (state, action) => {
    window.location.href = Whitelabel.sonicUrl + action.data.suffix;
    return state;
  },

  ////// ENABLE/DISABLE LOGNOICE ACTIONS

  enableLogging: (state, _action) => {
    Logger.initStream(Session.get('sessionId'));
    Logger.setEnabled(true);
    Session.set(['settings', 'general', 'lognoiceEnabledByAdmin'], true);
    return state;
  },

  disableLogging: (state, _action) => {
    Logger.killStream();
    Logger.setEnabled(false);
    Session.set(['settings', 'general', 'lognoiceEnabledByAdmin'], false);
    return state;
  },

  //dump this part of the state
  dumpBaseState: (state, _action) => {
    Logger.log(`[baseStateDump] - ${JSON.stringify(state.toJS())}`, 'warn');
    return state;
  },

  takeScreenshot: (state, action) => {
    // if (document) {
    //   let quality = 50;
    //   if (action.data.data && action.data.data[1] && action.data.data[1].quality) {
    //     quality = parseInt(action.data.data[1].quality) || quality;
    //   }
    //   const screenshotTarget = document.body;
    //   html2canvas(screenshotTarget, {
    //     //imageTimeout: 20000,
    //     scale: quality / 100,
    //   }).then((canvas) => {
    //     const base64image = canvas.toDataURL('image/png');
    //     Logger.log(`[screenshot] - ${base64image}`, 'warn');
    //   });
    // }
    return state;
  },
};

export default function reducer(state = initialState, action) {
  //always log all actions unless specifically requested
  if (config.support.tools.rawActions) {
    console.log(toCamelCase(action.type), action);
  } else if (!action.noLog) {
    Logger.logAction(action);
  }

  //always record all actions except when specifically requested
  if (!action.noTelem) {
    Telemetry.recordAction(action);
  }

  //message catch all
  //we look for things with errors in them ... unless we specifically say that that's ok
  if (config.support.handleOwnErrors.indexOf(action.type) === -1) {
    if (action.data && action.data.status && action.data.status !== 'ok') {
      //status is not ok
      //look for a code
      let code = action.code;
      if (action.data.code) {
        if (typeof action.data.code === 'string') {
          code = action.data.code;
        } else {
          code = JSON.stringify(action.data.code);
        }
      }

      //the code says the user is not authenticated (for something) so we do a logout
      if (
        code === 'auth_error' ||
        code === 'authentication_error' ||
        code === 'authentication_failed'
      ) {
        return doLogout(state, code);
      }

      //set a user toast message
      if (code !== 'one_at_a_time') {
        //Adriano wants this ignored
        state = state.setIn(
          ['messages', +new Date() + '/' + Math.floor(1000 * Math.random())],
          fromJS({
            message:
              (code ? `[${code.replace(/_/g, ' ').toLowerCase()}]` : '') +
              `${action.data.message || extractErrorMessage(action.data.data)}`,
            level: action.data.status,
          })
        );
      }
    }
  }

  let _action = toCamelCase(action.type);
  return functions[_action] ? functions[_action](state, action) : state;
}

export let actions = {};
for (let ct in functions) {
  actions[ct] = (data, noGA, noLog) => ({ type: ct, data, noGA, noLog });
}
